import React, { useEffect, useState } from "react";
import { VideoCard } from "./VideoCard";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PerCourseVideo } from "../../../backend/PerCourseData";

const useStyles = makeStyles(() => ({
  heading: {
    padding: "38px 0px",
    maxWidth: 800,
  },
}));

export const VideoList = () => {
  const classes = useStyles();

  const [videoData, setVideoData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getVideoCollection(id);
  }, [id]);

  const getVideoCollection = async (id) => {
    const result = await PerCourseVideo(id);
    setVideoData(result);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
        {videoData?.map((video) => (
          <VideoCard item={video} />
        ))}
      </div>
    </React.Fragment>
  );
};
