import { useEffect, useState } from "react";
import { SyllabusCard } from "./SyllabusCard";
import { PerCourseSyllabus } from "../../../backend/PerCourseData";
import { useParams } from "react-router-dom";

export const Syllabus = () => {
  const { id } = useParams();
  const [syllabusData, setSyllabusData] = useState([]);

  useEffect(() => {
    getSyllabus(id);
  }, [id]);

  const getSyllabus = async (id) => {
    const res = await PerCourseSyllabus(id);
    setSyllabusData(res);
  };

  return (
    <div style={{ width: "100%", paddingTop: 20 }}>
      {syllabusData?.map((data) => (
        <SyllabusCard data={data} />
      ))}
    </div>
  );
};
