import { addDoc, collection } from "firebase/firestore";
import db from "../firebase";

export const addShopOrders = async (payload) => {
  try {
    const docRef = collection(db, "Orders");
    await addDoc(docRef, payload);
    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, error: error };
  }
};
