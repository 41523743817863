import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
// import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  descriptionContainer: {
    margin: "45px 0px",
    padding: "30px 45px",
    background: "#14024212",
    borderRadius: 13,
  },
  descriptionTitle: {
    color: "#24007C",
    fontWeight: "700",
    width: 130,
    borderBottom: "3px solid #24007C",
  },
}));

const ProductDescription = ({ product }) => {
  const classes = useStyles();
  const { description } = product;

  const descriptionRef = useRef(null);

  useEffect(() => {
    descriptionRef.current.innerHTML = description;
  }, [description]);

  return (
    <div className={classes.descriptionContainer}>
      <Typography
        className={classes.descriptionTitle}
        variant="h5"
        gutterBottom
      >
        Description
      </Typography>
      <div style={{ fontSize: 20 }} ref={descriptionRef} />
    </div>
  );
};

export default ProductDescription;
