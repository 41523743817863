import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Typography } from "@material-ui/core";
import { EditProductQuantity } from "./ProductQuantity";
import ProductContext from "../../components/Context/ProductContext";

const useStyles = makeStyles({
  root: {
    width: 913,
    height: 210,
    background: "#F9F8F8",
    boxShadow: "none",
    borderRadius: 9,
    display: "flex",
    justifyContent: "space-between",
    padding: "26px 38px 42px 50px",
  },
  detailsContainer: {
    display: "flex",
    width: 585,
    gap: 43,
  },
  productName: {
    fontWeight: "700",
    overflowY: "hidden",
    marginBottom: 12,
  },
  deleteButton: {
    border: "none",
    background: "none",
    fontSize: 16,
    paddingTop: 3,
    color: "#00488D",
  },
  price: {
    fontWeight: "600",
  },
  quantityContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 43,
  },
});

export const ProductCard = ({ product }) => {
  const classes = useStyles();
  const { deleteItemFromCart } = useContext(ProductContext);
  const {
    productMRP,
    discountPrice,
    docId,
    productCategory,
    productImage,
    productName,
    quantity,
    stock,
  } = product;

  console.log(product);

  const handleDeleteProduct = () => {
    deleteItemFromCart(docId);
    console.log("hi");
  };

  return (
    <Card className={classes.root}>
      <div className={classes.detailsContainer}>
        <img
          src={productImage}
          alt={productImage}
          width={102}
          height={142}
          style={{ objectFit: "cover" }}
        />
        <div>
          <Typography variant="h5" className={classes.productName}>
            {productName}
          </Typography>
          <Typography>
            <span style={{ textDecoration: "line-through" }}>
              ₹{productMRP}
            </span>{" "}
            <b>₹{discountPrice}</b> <span>X {quantity}</span>
          </Typography>
          <Typography>Category: {productCategory}</Typography>
          {parseFloat(stock) > 0 ? <Typography>In stock</Typography> : null}
          <button
            className={classes.deleteButton}
            onClick={handleDeleteProduct}
          >
            Delete
          </button>
        </div>
      </div>
      <div className={classes.quantityContainer}>
        <Typography variant="h4" className={classes.price}>
          ₹{+quantity * +discountPrice}
        </Typography>
        <EditProductQuantity quantity={quantity} prodId={docId} />
      </div>
    </Card>
  );
};
