import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getQuizDetails } from "../../backend/getDataFromFirestore/getQuizData";
import Home from "../../components/Home";

const CourseQuiz = () => {
  const { quizDocId } = useParams();
  const [courseQuiz, setCourseQuiz] = useState({});
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(null);

  useEffect(() => {
    handlerQuizData(quizDocId.trim());
  }, []);

  const handlerQuizData = async (quizId) => {
    const { data } = await getQuizDetails(quizId);
    setCourseQuiz(data);
    const timer = await getTime(data.duration);
    setTime(timer);
    setLoading(false);
  };

  const getTime = async (sec) => {
    const dates = new Date();
    dates.setSeconds(dates.getSeconds() + sec);
    return dates;
  };

  return (
    <>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <Home courseQuiz={courseQuiz} time={time} />
      )}
    </>
  );
};

export default CourseQuiz;
