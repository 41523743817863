import React from "react";
import Navbar from "../../components/Navbar";
import { ReferHeader } from "./ReferHeader";

export const Refer = () => {
  return (
    <React.Fragment>
      <Navbar />
      <ReferHeader />
    </React.Fragment>
  );
};
