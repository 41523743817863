import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 900,
    },
  },
  container: {
    width: 700,
  },
  button: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    width: "30px",
    height: "30px",
    position: "absolute",
    right: 0,
    margin: 12,
  },
}));

export const ImagePreview = ({ open, handleClose, previewImage }) => {
  console.log(open, previewImage);
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialog}>
      <div className={classes.container}>
        <Magnifier
          imageSrc={previewImage}
          imageAlt={previewImage}
          largeImageSrc={previewImage}
          mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK}
          touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
        />
      </div>
    </Dialog>
  );
};
