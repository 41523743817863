import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';



const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#713ef0",
    width: "100%",
    height: "200px",
  },
  Title: {
    display: "flex",
    justifyContent: "space-between",
    padding: "45px 35px",
    color : "#fff",
    fontWeight : 600,
    fontFamily: "Outfit, sans-serif",
  },
  Header : {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
  },
  fontType : {
    fontFamily: "Outfit, sans-serif",
    fontWeight : 600,
    fontSize : "35px",
    wordSpacing : "1px",
  },
  para : {
    fontFamily: "Outfit, sans-serif",
    fontWeight : 500,
    fontSize : "20px",
    letterSpacing : "0.5px",
  },
  loader : {
    width: "100px",
    height: "100px",

    "& .MuiCircularProgress-svg" : {
    // backgroundColor : "#fff",
    },

    "& .MuiCircularProgress-circleDeterminate" :{
        stroke : "#23C346",
        fill : ""
    },
  },
}));



function CircularProgressWithLabel(props) {
    const classes = useStyles();
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} className={classes.loader} style={{width: "120px" , height : "120px"}}/>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography variant="h2" style={{fontSize : "30px",  fontWeight : 600}}>{`${Math.round(
            props.value,
          )}%`}</Typography>
          <Typography variant="h2" style={{fontSize : "15px" , fontWeight : 700}}>Completed</Typography>
          
        </Box>
      </Box>
    );
  }

  const title = "HTML";
  const description = "HTML is the standard markup";
  const image = "/assets/Quiz/html.svg";

const QuizHeader = ({ quizName }) => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(100);
  return (
    <div className={classes.container}>
      <div className={classes.Title}>

        <div className={classes.Header}>
          <img src={image} alt="" style={{width: "95px", height: "95px"}}/>
          <div>
          <Typography variant="h4" style={{}} className={classes.fontType} gutterBottom>
            {title} (Hyper Text Markup Language)
          </Typography>
          <Typography variant="body2" component='p' className={classes.para}>{description}</Typography>
          </div>
        </div>

        <div>
        <CircularProgressWithLabel value={progress} />
        </div>
      </div>
    </div>
  );
};

export default QuizHeader;
