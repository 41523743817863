import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { EventButton } from "../../../common/Button";
import { Link, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 15,
    width: "100%",
    height: "100%",
    position: "relative",
  },
  container: {
    height: 444,
    width: 598,
    boxShadow: "3.11321px 3.11321px 31.1321px rgba(0, 0, 0, 0.06)",
    marginTop: 50,
  },
  regDate: {
    background: "rgba(255, 255, 255, 0.85)",
    position: "absolute",
    height: 40,
    width: 320,
    top: 15,
    borderTopRightRadius: 999,
    borderBottomRightRadius: 999,
    display: "flex",
    alignItems: "center",
    padding: "0px 15px",
  },
  content: {
    padding: "30px 30px",
    background: "#fff",
  },
  description: {
    color: "rgba(75, 76, 71, 0.72)",
    fontSize: 15,
    padding: "5px 0px",
    maxHeight: 45,
    minHeight: 45,
  },

  button: {
    marginTop: 30,
  },
  eventPoster: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  media: {
    height: 180,
    width: "100%",
  },
  disableEvent: {
    pointerEvents: "none",
    opacity: 0.6,
  },
}));

export const EventCard = ({ event }) => {
  const classes = useStyles(event);
  const disable = `${classes.container} ${classes.disableEvent}`;

  const onClickUrl = (e, url) => {
    e.preventDefault();
    const newWindow = window.open(url, "_blank", "noopener, noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const formatDate = (timeStamp) => {
    const fullDate = new Date(timeStamp?.seconds * 1000);
    const date = fullDate?.getDate();
    const month = fullDate.toLocaleString("default", { month: "long" });

    let hours = fullDate.getHours();
    let minutes = fullDate.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + "" + ampm;
    return `${date} ${month}, ${strTime}`;
  };

  const registrationDate = new Date(event?.registrationDate?.seconds * 1000);
  const todayDate = new Date();

  return (
    <div className={todayDate > registrationDate ? disable : classes.container}>
      <Card className={classes.root}>
        <Link to={`/events/${event?.docId}`}>
          <div className={classes.media}>
            <img src={event?.eventPoster} alt="" className={classes.media} />
          </div>
        </Link>

        <div className={classes.regDate}>
          <div
            style={{
              background: "#17FF03",
              width: 10,
              height: 10,
              borderRadius: 999,
            }}
          />
          <Typography
            style={{
              color: "#000",
              fontSize: 13,
              fontWeight: 600,
              marginLeft: 15,
            }}
          >
            Registrations open till {formatDate(event?.registrationDate)}
          </Typography>
        </div>

        <div className={classes.content}>
          <Typography
            variant=""
            gutterBottom
            style={{
              color: "#000",
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {event?.eventName}
          </Typography>

          <Typography
            style={{
              color: "#626262",
              fontSize: 15,
              marginTop: 5,
            }}
          >
            STARTS ON
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 5,
              gap: 10,
              color: "#4E4E4E",
            }}
          >
            <DateRangeOutlinedIcon />
            <p>{formatDate(event?.eventDate)}</p>
          </div>
          <div className={classes.description}>{event?.eventDescription}</div>
          <div className={classes.button}>
            <EventButton
              onClick={(e) => onClickUrl(e, event?.formLink)}
              variant="contained"
            >
              Register
            </EventButton>
          </div>
        </div>
      </Card>
    </div>
  );
};
