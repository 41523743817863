import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  blogContainer: {
    "& a": {
      color: "blue",
      textDecoration: "underline",
    },
  },
  blogImage: {
    width: "100%",
    height: "350px",
    marginTop: "20px",
  },
  BgImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  blogDesc: {
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
}));

const BlogDescription = (props) => {
  const { blogImage, blogDescription } = props.blogData;
  const blogRef = useRef(null);
  useEffect(() => {
    blogRef.current.innerHTML = blogDescription;
  }, [blogDescription]);
  const classes = useStyles();
  return (
    <>
      <div className={classes.blogContainer}>
        <div className={classes.blogImage}>
          <img src={blogImage} alt="blogImage" className={classes.BgImg} />
        </div>

        <div className={classes.blogDesc} ref={blogRef}></div>
      </div>
    </>
  );
};

export default BlogDescription;
