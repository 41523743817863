import { Button, Card, CardMedia, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 377,
    width: 410,
    borderRadius: 10,
    padding: "15px 15px 25px",
    boxShadow: "2px 4px 30px 0px #00000040",
  },
  media: {
    height: 160,
    width: 381,
    borderRadius: 4,
    margin: "0px 15",
  },
  detailsContainer: {
    padding: "19px 0px 15px",
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
  courseTitle: {
    textTransform: "uppercase",
    color: "#F6B501",
    fontWeight: "700",
  },
  subDetails: {
    display: "flex",
    gap: 12,
    fontWeight: "500",
  },
  exploreButton: {
    background: "#5C36BB",
    height: 40,
    width: 376,
    borderRadius: 0,
    color: "#FFFFFF",
    fontWeight: "600",
    "&:hover": {
      background: "#5C36BB",
    },
  },
}));

export const CourseCard = ({ course }) => {
  const classes = useStyles();

  return (
    <Link to={`/course-details/${course.courseId}`}>
      <Card className={classes.root}>
        <CardMedia className={classes.media} image={course?.CourseLogo} />
        <div className={classes.detailsContainer}>
          <Typography variant="h6" className={classes.courseTitle}>
            {course?.courseTitle}
          </Typography>
          <Typography className={classes.subDetails}>
            <AccessibilityNewIcon />
            <span className={classes.courseTitle}>12-15</span>Lectures
          </Typography>
          <Typography className={classes.subDetails}>
            <PeopleAltIcon />
            <span className={classes.courseTitle}>600+</span>Student enrolled
          </Typography>
        </div>
        <Button className={classes.exploreButton}>Explore</Button>
      </Card>
    </Link>
  );
};
