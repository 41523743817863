import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ProductCard } from "./ProductCard";
import { Typography } from "@material-ui/core";
import { CartSubTotal } from "./CartSubTotal";
import ProductContext from "../../components/Context/ProductContext";
import { getCartProductData } from "../../backend/getData";
import LoginNavbar from "../Home/LoginNavbar";

const useStyles = makeStyles({
  root: {
    padding: "57px 72px 0px 92px",
    "& .MuiTypography-root": {
      fontFamily: "Inter",
    },
  },
  heading: {
    paddingBottom: 25,
  },
  cartContainer: { display: "flex", gap: 30 },
  products: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
});

export const ShoppingCart = () => {
  const classes = useStyles();
  const { cartData } = useContext(ProductContext);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleProducts = async (cartIds) => {
    if (cartIds?.length) {
      const { successful, data } = await getCartProductData(cartIds);
      if (successful && data?.length) {
        const product = data?.map((product) => {
          const qtyObj = cartData?.find((data) => data?.id === product?.docId);
          return { ...product, quantity: qtyObj?.qty };
        });
        setFilteredProducts(product);
      }
    } else {
      setFilteredProducts([]);
    }
  };

  useEffect(() => {
    const cartIds = cartData?.map((item) => item?.id);
    handleProducts(cartIds);
  }, [cartData]);

  return (
    <>
      <LoginNavbar />
      <div className={classes.root}>
        <Typography variant="h4" className={classes.heading}>
          Shopping Cart
        </Typography>
        <div className={classes.cartContainer}>
          <div className={classes.products}>
            {filteredProducts?.map((product) => (
              <ProductCard key={product?.docId} product={product} />
            ))}
          </div>
          <CartSubTotal products={filteredProducts} />
        </div>
      </div>
    </>
  );
};
