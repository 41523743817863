import React, { useEffect, useState } from "react";
import { PptCard } from "./PptCard";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PerCoursePpt } from "../../../backend/PerCourseData";

const useStyles = makeStyles(() => ({
  heading: {
    padding: "38px 0px",
    maxWidth: 800,
  },
}));

export const Ppt = () => {
  const classes = useStyles();

  const [pptData, setPptData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getPptCollection(id);
  }, [id]);

  const getPptCollection = async (id) => {
    const result = await PerCoursePpt(id);
    setPptData(result);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
        {pptData?.map((ppt) => (
          <PptCard item={ppt} />
        ))}
      </div>
    </React.Fragment>
  );
};
