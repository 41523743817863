import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import VideoCard from "./VideoCard";
const useStyles = makeStyles(() => ({
  main: {
    padding: "15px 25px",
  },
  quizBody: {
    marginTop: 20,
  },
}));

const VideoSection = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.quizBody}>
        <Typography variant="h6">TABLES VIDEO EXPLANATION</Typography>
        <VideoCard />
      </div>

      <div className={classes.quizBody}>
        <Typography variant="h6">FORM HANDLING VIDEO EXPLANATION</Typography>
        <VideoCard />
      </div>
    </>
  );
};

export default VideoSection;
