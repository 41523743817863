import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";
import StarIcon from "@material-ui/icons/Star";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Box from "../components/Box";
import bgImg from "../assets/code.jpg";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../features/userSlice";
import { setQuiz } from "../features/userSlice";
import quizmap from "../components/Quizmap";

const Dashbord = () => {
  const [loading, setLoading] = useState(true);
  const [quizArr, setQuizArr] = useState(quizmap);
  const [search, setSearch] = useState("");

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        setQuiz({
          showsExit: false,
        })
      );
    }, 5000);
  }, []);

  const scrollRef = useRef(null);
  const scrollRef1 = useRef(null);
  // const [isDown, setDown] = useState(false)

  const [scroll, setScroll] = useState(0);
  // let startX = 0
  // let scrollLeft = 0

  const Prev = () => {
    scrollRef.current.scrollLeft -= 400;
  };

  const Next = () => {
    scrollRef.current.scrollLeft += 400;
  };

  //  drag and scroll code
  // const handleMouseDown = (e) => {
  //     const {clientX} = e;
  //     const {scrollLeft} = e.target;
  //     // const isDown = true;
  //     setDown(true)
  //     const startX = clientX - scrollLeft;
  //     scrollRef.current.scrollLeft = scrollLeft;
  // }

  // const handleMouseLeave = () => {
  //     // isDown = false;
  //     setDown(false)
  // // }

  // const handleMouseUp = () => {
  //     // isDown = false;
  //     setDown(false)
  // }

  // const handleMouseMove = (e) => {
  //     if(!isDown) return;
  //     e.preventDefault();
  //     const {clientX} = e;
  //     const walk = (clientX - startX) * 3;
  //     scrollRef.current.scrollLeft = scrollLeft - walk;

  // const x = e.pageX - scrollRef.current.offsetLeft
  // const walk = x - startX;
  // scrollRef.current.scrollLeft = walk
  // }

  const handleScroll = (e) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.target;
    setScroll((scrollLeft / (scrollWidth - clientWidth)) * 100);
  };

  const Back = () => {
    scrollRef1.current.scrollLeft -= 400;
  };

  const Move = () => {
    scrollRef1.current.scrollLeft += 400;
  };

  // {
  //     user.showsExit ? (
  //         <Loader/>
  //     ) : (

  // {
  //     user.showsExit ? <Loader/> : null

  // }

  return (
    <>
      {user.showsExit ? <Loader /> : null}
      <Container>
        <Navbar setSearch={setSearch} setQuizArr={setQuizArr} />
        <WelSection>
          <LeftDiv>
            <h2>Welcome 👋</h2>
          </LeftDiv>

          <RightDiv>
            <ul>
              <li>
                <Circle>
                  <TodayOutlinedIcon />
                </Circle>
                <p>Daily Rewards : 10</p>
              </li>
              <li>
                <Circle>
                  <StarIcon />
                </Circle>
                <p>Stars : 3/5</p>
              </li>
              <li>
                <Circle>
                  <StarIcon />
                </Circle>
                <p>Badges : 22</p>
              </li>
            </ul>
          </RightDiv>
        </WelSection>

        <QuizSection>
          <Header>
            <Heading>
              <h2>Topicwise Quizzes</h2>
              <p>A curated list of topic wise quizzes</p>
            </Heading>

            <ViewOtps>
              <p>View more</p>
              <NavigateNextIcon />
            </ViewOtps>
          </Header>

          <ScrollSection>
            <Btns onClick={Prev}>
              <NavigateBeforeIcon />
            </Btns>
            <Quizes ref={scrollRef} onScroll={handleScroll}>
              {quizArr.map((item) => {
                return (
                  <Box
                    key={item.id}
                    title={item.title}
                    desc={item.desc}
                    time={item.time}
                    quiz={item.quiz}
                    img={item.img}
                    bgImg={item.bgImg}
                    link={item.link}
                    second={item.second}
                  />
                );
              })}
            </Quizes>
            <Btns onClick={Next}>
              <NavigateNextIcon />
            </Btns>
          </ScrollSection>
        </QuizSection>

        <QuizSection>
          <Header>
            <Heading>
              <h2>Topicwise Quizzes</h2>
              <p>A curated list of topic wise quizzes</p>
            </Heading>

            <ViewOtps>
              <p>View more</p>
              <NavigateNextIcon />
            </ViewOtps>
          </Header>

          <ScrollSection>
            <Btns onClick={Back}>
              <NavigateBeforeIcon />
            </Btns>
            <Quizes ref={scrollRef1} onScroll={handleScroll}>
              {quizArr.map((item) => {
                return (
                  <Box
                    key={item.id}
                    title={item.title}
                    desc={item.desc}
                    time={item.time}
                    quiz={item.quiz}
                    img={item.img}
                    bgImg={item.bgImg}
                    link={item.link}
                    second={item.second}
                  />
                );
              })}
            </Quizes>
            <Btns onClick={Move}>
              <NavigateNextIcon />
            </Btns>
          </ScrollSection>
        </QuizSection>
      </Container>
    </>
  );
};

const Quizes = styled.div`
  position: relative;
  display: flex;
  gap: 25px;
  padding: 20px 0px;
  align-items: center;
  overflow-x: scroll;
  /* overflow: auto; */
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  transition: all 0.5s ease;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Btns = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease;
  scroll-behavior: smooth;
`;

const ScrollSection = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.5s ease;
  scroll-behavior: smooth;
`;

const Container = styled.div`
  width: 100%;
  background: #eeeeee;
  /* height: 100vh; */
`;

const WelSection = styled.div`
  background: #5c36bb;
  color: #fff;
  height: 180px;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: c enter;
  justify-content: space-between;
`;

const LeftDiv = styled.div`
  /* background-color: red; */
  h2 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 1.1;
  }
  span,
  h5,
  p {
    font-size: 30px;
    font-weight: 100;
    font-weight: lighter;
  }
`;

const RightDiv = styled.div`
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 35px;
  }
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.9px;
  }
`;

const Circle = styled.div`
  background: rgba(255, 255, 255, 0.2);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f2b318;
  /* font-size: 55px; */
  .MuiSvgIcon-root {
    font-size: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
`;

const Heading = styled.div`
  color: #333;
`;

const ViewOtps = styled.div`
  display: flex;
  align-items: center;
  color: #333;
`;

const QuizSection = styled.div`
  padding: 10px 25px;
`;

export default Dashbord;
