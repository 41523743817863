import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Fab, IconButton, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 552,
    display: "flex",
    justifyContent: "space-between",
    background: "linear-gradient(98.09deg, #5C36BB 3.04%, #70A5AA 81.82%)",
    padding: "0px 47px 48px 58px",
    color: "#FFFFFF",
  },
  referral: {
    fontWeight: "600",
  },
  referralContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#D9D9D98F",
    color: "#000000BA",
    height: 44,
    width: 472,
    borderRadius: 5,
    padding: "0px 15px",
  },
  iconTab: {
    background: "transparent",
    boxShadow: "none",
  },
}));

export const ReferHeader = () => {
  const classes = useStyles();
  const referImagePath = "/assets/refer-and-earn/refer-and-earn.svg";
  const shareLink = "https://Roguecode.com/referral";

  const handlerCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    toast.success("Link Copied");
  };

  return (
    <div className={classes.container}>
      <div style={{ width: 729, paddingTop: 58 }}>
        <Typography variant="h3">Refer & Earn</Typography>
        <Typography variant="h6" style={{ paddingBottom: 9 }}>
          Earn Reward while benefiting your friend.
        </Typography>
        <Typography style={{ fontWeight: "300" }}>
          Refer Rogue Code to your friends which benefit for their career & you
          gain the reward. Sapien sed nunc vel hendrerit. Tristique elementum
          habitasse viverra sed ac. Hendrerit fermentum velit in nulla vulputate
          neque integer mattis. Pellentesque mi pellentesque laoreet aliquam
          arcu eget.
        </Typography>
        <Typography style={{ fontWeight: "300" }}>
          Sapien sed nunc vel hendrerit. Tristique elementum habitasse viverra
          sed ac. Hendrerit fermentum velit in nulla vulputate neque integer
          mattis. Pellentesque mi pellentesque laoreet aliquam arcu eget.
        </Typography>
        <div style={{ paddingTop: 37, paddingBottom: 12 }}>
          <Typography className={classes.referral}>
            Your Referral Link
          </Typography>
          <div className={classes.referralContainer}>
            <Typography variant="subtitle2" style={{ fontWeight: "600" }}>
              {shareLink}
            </Typography>
            <IconButton aria-label="view" onClick={handlerCopyLink}>
              <FileCopyIcon />
            </IconButton>
          </div>
          <Typography variant="body2">
            Copy this link & share it with your friends
          </Typography>
        </div>
        <div>
          <Typography>More way to invite your friend</Typography>
          <div className={classes.sharePost}>
            <Fab
              aria-label="linkedIn"
              color="neutral"
              className={classes.iconTab}
              title="Share on LinkedIn"
            >
              <LinkedinShareButton
                url={shareLink}
                quote={"New Referral"}
                hashtag={"#react"}
              >
                <LinkedinIcon size={40} round />
              </LinkedinShareButton>
            </Fab>

            <Fab
              aria-label="linkedIn"
              color="neutral"
              className={classes.iconTab}
              title="Share on Whatsapp"
            >
              <WhatsappShareButton
                url={shareLink}
                quote={"New Referral"}
                hashtag={"#react"}
              >
                <WhatsappIcon size={40} round />
              </WhatsappShareButton>
            </Fab>

            <Fab
              aria-label="linkedIn"
              color="neutral"
              className={classes.iconTab}
              title="Share on Twitter"
            >
              <TwitterShareButton
                url={shareLink}
                quote={"New Referral"}
                hashtag={"#react"}
              >
                <TwitterIcon size={40} round />
              </TwitterShareButton>
            </Fab>
          </div>
        </div>
      </div>
      <img src={referImagePath} alt={"refer-icon"} />
    </div>
  );
};
