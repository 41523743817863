import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CompletedQuiz from "./CompletedQuiz";
import { useTimer } from "react-timer-hook";
import ConfirmSubmit from "./ConfirmSubmit";
import Solution from "./Solution";
import { keyframes } from "styled-components";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import LensOutlinedIcon from "@material-ui/icons/LensOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const RightPanel = (props) => {
  const {
    quizData,
    expiryTimestamp,
    setCurrentQue,
    setSubmitted,
    setShowView,
    changque,
    setViewSoln,
  } = props;

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState("close");
  const [submitQuiz, setSubmitQuiz] = useState("close");
  const [score, setScore] = useState(0);
  const [progressBar, setProgressBar] = useState(0);
  const [showCompleted, setShowCompleted] = useState({});
  const [view, setView] = useState(false);

  useEffect(() => {
    setCurrentQue(currentQuestion + 1);
    const maths = ((currentQuestion + 1) / quizData?.questions?.length) * 100;
    setProgressBar(maths.toFixed(2));
  }, [currentQuestion, setCurrentQue]);

  const { seconds, minutes, restart, pause, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => SubmitQuiz(),
  });

  useEffect(() => {
    setCurrentQuestion(changque);
  }, [changque]);

  const handlerNext = () => {
    if (currentQuestion + 1 < quizData?.questions.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSubmitted(showCompleted);
    } else {
      setCurrentQuestion(0);
    }
  };

  const showScorecard = () => {
    switch (showScore) {
      case "open":
        setShowScore("close");
        break;
      case "close":
        setShowScore("open");
        break;
      default:
        setShowScore("close");
    }
  };

  const SubmitQuiz = () => {
    switch (submitQuiz) {
      case "open":
        setScore(0);
        setShowCompleted({});
        setSubmitted({});
        const time = new Date();
        time.setSeconds(time.getSeconds() + quizData?.duration);
        restart(time);
        setCurrentQuestion(0);
        setSubmitQuiz("close");
        break;
      case "close":
        pause();
        setSubmitQuiz("open");
        break;
      default:
        setSubmitQuiz("close");
    }
  };

  const handlerView = () => {
    setSubmitQuiz("close");
    setCurrentQuestion(0);
    setView(!view);
    setViewSoln(!view);
    pause();

    setShowView(showCompleted);
    setCurrentQue(null);
    setSubmitted({});
  };

  const handlerPrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const getAns = (value, id) => {
    if (showCompleted.hasOwnProperty(currentQuestion)) {
      if (
        showCompleted[currentQuestion] ===
        quizData?.questions[currentQuestion]?.answer
      ) {
        setScore(score - 1);
      }
      setShowCompleted({
        ...showCompleted,
        [currentQuestion]: value,
      });
      if (value === quizData?.questions[currentQuestion]?.answer) {
        setScore(score + 1);
      }
    } else {
      setShowCompleted({
        ...showCompleted,
        [currentQuestion]: value,
      });
      if (value === quizData?.questions[currentQuestion]?.answer) {
        setScore(score + 1);
      }
    }
  };

  return (
    <>
      <Container>
        <Header>
          <LeftHeader>
            <NavigateBeforeIcon />
            <NavigateNextIcon />
            <h3>Language : {quizData?.title?.toUpperCase()} </h3>
          </LeftHeader>

          <RightHeader>
            <WatchLaterOutlinedIcon />
            <h3>
              Time Remaining :{" "}
              <span
                style={{ color: minutes < 1 && hours === 0 ? "#F44336" : "" }}
              >
                {minutes < 10 ? `0${minutes}` : minutes} :{" "}
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>{" "}
            </h3>
          </RightHeader>
          {minutes < 1 && hours === 0 && (
            <HurryUP>
              <h5>Hurry Up !!</h5>
            </HurryUP>
          )}
        </Header>

        <Slider>
          <h3>
            {currentQuestion + 1}/{quizData?.questions.length}
          </h3>
          <Bar>
            <Progress style={{ width: `${progressBar}%` }}></Progress>
          </Bar>
        </Slider>

        <Question>
          <h3>{quizData?.questions[currentQuestion].question}</h3>
          {quizData[currentQuestion]?.code && (
            <pre className="mycode">{quizData[currentQuestion]?.code}</pre>
          )}
        </Question>
        <Options>
          {Object.keys(quizData?.questions[currentQuestion]?.options).map(
            (option, index) => {
              return (
                <Otp
                  key={index}
                  disabled={view}
                  onClick={() => getAns(option, `options${option}`)}
                  id={`options${option}`}
                  value={option}
                  active={
                    view
                      ? option ===
                        quizData?.questions[currentQuestion]?.["answer"]
                        ? "setGreen"
                        : "setRed"
                      : showCompleted[currentQuestion] === option
                      ? "active"
                      : null
                  }
                  className={
                    view
                      ? option ===
                        quizData?.questions[currentQuestion]?.["answer"]
                        ? "setGreen"
                        : showCompleted[currentQuestion] === option
                        ? "setRed"
                        : ""
                      : showCompleted[currentQuestion] === option
                      ? "active"
                      : null
                  }
                >
                  {view ? (
                    option ===
                    quizData?.questions[currentQuestion]?.["answer"] ? (
                      <CheckCircleIcon />
                    ) : showCompleted[currentQuestion] === option ? (
                      <CancelIcon />
                    ) : (
                      <LensOutlinedIcon />
                    )
                  ) : showCompleted[currentQuestion] === option ? (
                    <CheckCircleIcon />
                  ) : (
                    <LensOutlinedIcon />
                  )}
                  {quizData.questions[currentQuestion]?.options[option]}
                </Otp>
              );
            }
          )}
        </Options>
        <ButtonWrapper>
          <button onClick={handlerPrev}>
            <NavigateBeforeIcon />
            Previous
          </button>
          {currentQuestion + 1 === quizData?.questions.length && !view ? (
            <button onClick={showScorecard}>
              Submit
              <NavigateNextIcon />
            </button>
          ) : (
            <button onClick={handlerNext}>
              Next Question
              <NavigateNextIcon />
            </button>
          )}
        </ButtonWrapper>
        <CompletedQuiz
          handlerView={handlerView}
          minutes={minutes}
          quizLength={quizData?.questions.length}
          score={score}
          submitQuiz={submitQuiz}
          handlerSubmitQuiz={SubmitQuiz}
        />

        <ConfirmSubmit
          minutes={minutes}
          seconds={seconds}
          quizName={quizData?.title}
          score={score}
          showScore={showScore}
          setShowScore={setShowScore}
          showScorecard={showScorecard}
          SubmitQuiz={SubmitQuiz}
          quizLength={quizData?.questions.length}
          quizTime={quizData?.duration}
          quizId={quizData?.docId ?? quizData?.docID}
        />

        <Solution
          view={view}
          showCompleted={showCompleted}
          quizData={quizData}
          currentQuestion={currentQuestion}
        />
      </Container>
    </>
  );
};

const Question = styled.div`
  text-align: center;
  padding: 30px 0;
  height: 258px;
  margin: 10px 0;
  h3 {
    word-wrap: break-word;
  }
`;

const Container = styled.div`
  background-color: #eee;
  width: 80%;
  flex-grow: 1;
  height: 100vh;
  padding: 30px 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  h3 {
    color: #333333;
    font-size: 18px;
  }
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    font-size: 25px;

    &:first-child {
      margin-right: -15px;
    }
  }
`;

const RightHeader = styled.div`
  display: flex;
  align-items: center;
  min-width: 220px;
  overflow: hidden;
  color: #808080;
  h3 {
    color: #808080;
  }
`;

const popUp = keyframes`
0%{
  scale: 0;
  opacity: 0;
}
100% {
  opacity: 1;
  scale: 1;
}
`;

const HurryUP = styled.div`
  position: absolute;
  top: 100%;
  right: -20px;
  background: #f44336;
  padding: 10px 20px;
  border-radius: 999px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  color: #fff;
  margin-top: 10px;
  animation: ${popUp} 0.3s ease-in-out;
  box-shadow: 0 2px 5px #f44336;
`;

const Slider = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Bar = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 400px;
  height: 10px;
  border-radius: 5px;
  background: rgba(33, 150, 83, 0.2);
  position: relative;
  overflow: hidden;
`;

const Progress = styled.div`
  height: 100%;
  background: #219653;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
`;

const Otp = styled.button`
  background: #ebebeb;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #fff;
  color: #000;
  word-wrap: break-word;
  gap: 5px;
  p {
    font-weight: 600;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;

  button {
    width: 235px;
    outline: none;
    border: none;
    padding: 13px 0;
    border-radius: 50px;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    background: #1a1a1a;
    color: #fff;
    text-align: center;
    font-size: 13px;
    cursor: pointer;

    &:nth-child(2) {
      background: #f2b318;
      color: #000;
    }
  }
`;

export default RightPanel;
