import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  courseHeader: {
    height: 140,
    backgroundColor: "#5C36BB",
    width: "100%",
    textAlign: "center",
  },
  heading: {
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  textField: {
    marginTop: 20,
    height: 42,
    width: "45%",
  },
  inputTextField: {
    color: "#FFFFFF",
    background: "#FFFFFF4D",
  },
  searchBar: {
    margin: "15px auto",
    height: 45,
    width: "100%",
    maxWidth: 600,
    background: "rgba(255, 255, 255, 0.3)",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    padding: "0px 15px",
  },
}));

export const CoursesHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.courseHeader}>
      <div className={classes.heading}>
        <Typography variant="h4" style={{ fontWeight: 600 }}>
          Courses
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 400 }}>
          Courses designed to help develop your programming knowledge.
        </Typography>
        {/* <TextField
          className={classes.textField}
          size="small"
          placeholder="Search your course...."
          variant="outlined"
          InputProps={{
            startAdornment: <SearchIcon />,
            className: classes.inputTextField,
          }}
        /> */}
      </div>
    </div>
  );
};
