import db from "../firebase";
import { collection, getDocs, doc, getDoc, query } from "firebase/firestore";

export const PerCourseData = async (locationID) => {
  const dataRef = collection(db, "Course");
  try {
    const querySnapshot = await getDoc(doc(dataRef, locationID));
    return querySnapshot.data();
  } catch (error) {
    console.log(error);
  }
};

export const PerCourseQuiz = async (locationID) => {
  const data = [];
  const dataRef = collection(db, "Course", `${locationID}/Quiz`);
  try {
    const querySnapshot = await getDocs(dataRef);
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
  } catch (error) {
    console.log(error);
  }
  return data;
};

export const PerCourseAssignment = async (locationID) => {
  const data = [];
  const dataRef = collection(db, "Course", `${locationID}/Assignment`);
  try {
    const querySnapshot = await getDocs(dataRef);
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
  } catch (error) {
    console.log(error);
  }
  return data;
};

export const PerCourseSyllabus = async (locationID) => {
  const syllabusData = [];
  const syllabusRef = query(collection(db, "Course", `${locationID}/Syllabus`));
  try {
    const querySnapshot = await getDocs(syllabusRef);
    querySnapshot?.forEach((doc) => {
      syllabusData?.push(doc.data());
    });
  } catch (error) {
    console.log(error);
  }
  return syllabusData;
};

export const PerCoursePpt = async (locationID) => {
  const data = [];
  const dataRef = collection(db, "Course", `${locationID}/PPT`);
  try {
    const querySnapshot = await getDocs(dataRef);
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
  } catch (error) {
    console.log(error);
  }
  return data;
};

export const PerCourseVideo = async (locationID) => {
  const data = [];
  const dataRef = collection(db, "Course", `${locationID}/Video`);
  try {
    const querySnapshot = await getDocs(dataRef);
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
  } catch (error) {
    console.log(error);
  }
  return data;
};
