import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import image from "../../assets/WebDevPage/hero-bg.jpg";
import WebDevHeroWaves from "./WebDevHeroWaves";
import webDevHero from "../../assets/WebDevPage/hero-img-new2.png";
import Typewriter from "typewriter-effect";

function WebDevHero() {
  return (
    <div
      className=" w-full "
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className=" w-full relative z-20 bg-[#0C12A0]">
        <div className=" max-w-7xl mx-auto px-4 md:px-6 h-full flex justify-center items-center md:mt-0">
          <div className="flex-1 md:min-h-[84vh] w-full flex items-center justify-between px-2 md:px-6">
            <div className="w-[80%] h-full  flex flex-col items-start gap-8 md:gap-12">
              <h1 className="text-white/80 font-bold text-3xl md:text-5xl">
                Build Your Own Landing Page with{" "}
                <span className="text-white border-b-4 border-yellow-500">
                  Rogue Code
                </span>
              </h1>
              <p className="text-lg md:text-3xl text-white/75">
                We are a aspiring and motivated team of talented designers and
                developers who believe in the making of quality software that
                impacts people in a positive way.
              </p>
              <p className="text-lg md:text-3xl text-white/90">
                <Typewriter
                  options={{
                    strings: ["Love To Hear From You!"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </p>
              <button className="flex items-center gap-2 rounded-full py-2 md:py-6 px-8 bg-yellow-500 hover:bg-green-500 transition-all duration-300 ease-linear mb-8">
                <FaWhatsapp className="w-6 h-6" />
                <span className="text-2xl font-semibold text-white">
                  Ping Us!
                </span>
              </button>
            </div>

            <div className="w-[40%] h-full hidden md:block relative z-30">
              <img className="animated" src={webDevHero} alt="" />
            </div>
          </div>
        </div>
        <WebDevHeroWaves />
      </div>
    </div>
  );
}

export default WebDevHero;
