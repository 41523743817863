import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import { INPUT_IMAGE_PATH } from "../../common/const";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "200px",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#713ef0",
    padding: "50px 25px",
  },
  banner: {
    width: "400px",
    height: "500%",
    position: "absolute",
    top: "-50%",
    left: "30%",
    transform: "rotate(20deg)",
    backgroundColor: "#5C36BB",
  },
  profile: {
    position: "relative",
    width: "100%",
    color: "#fff",
    fontFamily: "Outfit, sans-serif",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  IconTab: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    width: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
  },
  Icons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  Heading: {
    fontWeight: 600,
    fontSize: "50px",
  },
}));

const QuizHeader = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.banner} />
        <div className={classes.profile}>
          <div>
            <Typography variant="h3" className={classes.Heading}>
              Welcome
            </Typography>
            <Typography variant="h5">Kishan Choudhary</Typography>
          </div>

          <div
            className=""
            style={{ display: "flex", alignItems: "center", gap: "50px" }}
          >
            <div className={classes.Icons}>
              <div className={classes.IconTab}>
                <img src={INPUT_IMAGE_PATH.QUIZ_CALENDER} alt="" />
              </div>
              <Typography variant="h6">Daily Rewards : 10</Typography>
            </div>

            <div className={classes.Icons}>
              <div className={classes.IconTab}>
                <img src={INPUT_IMAGE_PATH.QUIZ_STARS} alt="jh" />
              </div>

              <Typography variant="h6">Stars : 3/5</Typography>
            </div>

            <div className={classes.Icons}>
              <div className={classes.IconTab}>
                <img src={INPUT_IMAGE_PATH.QUIZ_MEDAL} alt="jh" />
              </div>
              <Typography variant="h6">Badges : 23</Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizHeader;
