import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: 20,
  },

  button: {
    backgroundColor: "#18A136",
    width: "100%",
    fontWeight: 500,
    color: "#fff",

    "&:hover": {
      backgroundColor: "#18A136",
    },
  },

  container: {
    height: "237px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    width: 390,
    borderRadius: 15,
    overflow: "hidden",
    marginTop: 10,
  },

  heading: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },

  videoSection: {
    height: 143,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#414141",
    marginTop: 15,
    borderRadius: 10,
    color: "#fff",
  },
  videoIcon: {
    fontSize: 40,
  },
}));

const VideoCard = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <Typography
            variant="h6"
            style={{ fontWeight: 600, color: "#474747" }}
          >
            What are tables in HTML ?
          </Typography>
          <div className={classes.videoSection}>
            <PlayCircleFilledWhiteIcon className={classes.videoIcon} />
          </div>
        </Card>
      </div>
    </>
  );
};

export default VideoCard;
