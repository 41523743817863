import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { ViewEventButton } from "../../../common/Button";
import { getEventData } from "../../../backend/getData";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import LoginNavbar from "../../Home/LoginNavbar";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    marginTop: 20,
    margin: "0px auto",
    maxWidth: 1400,
    marginBottom: 20,
  },
  descriptionEvent: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTime: {
    display: "flex",
    alignItems: "center",
    color: "#434343",
    gap: 10,
    paddingBottom: 10,
  },
  shortDesc: {
    color: "rgba(75, 76, 71, 0.72)",
    fontWeight: 400,
    fontSize: 20,
  },
  eventPoster: {
    width: "100%",
    height: 365,
    margin: "30px 0px",
  },
  poster: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  longDesc: {
    color: "rgba(75, 76, 71, 0.72)",
    fontWeight: 400,
    fontSize: 20,
  },
}));

export const ViewEvent = () => {
  const [eventDetails, setEventDetails] = useState({});
  const classes = useStyles();
  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    handlerGetEvent(id);
  }, [id]);

  const handlerGetEvent = async (id) => {
    await getEventData(id).then((res) => {
      setEventDetails({
        ...res,
        eventDate: new Date(res?.eventDate.seconds * 1000),
        registrationDate: new Date(res?.registrationDate.seconds * 1000),
      });
    });
  };

  const handlerOpenEvent = (e, url) => {
    e.preventDefault();
    const newWindow = window.open(url, "_blank", "noopener, noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <LoginNavbar />
      <div className={classes.container}>
        <Breadcrumbs>
          <Typography color="">Event</Typography>
          <Typography color="primary">View Event</Typography>
        </Breadcrumbs>

        <div className={classes.descriptionEvent}>
          <Typography gutterBottom>
            {eventDetails?.registrationDate?.toDateString()}
          </Typography>

          <Typography
            variant="h4"
            style={{ color: "#464646", fontWeight: 600, fontSize: 40 }}
            gutterBottom
          >
            {eventDetails?.eventName}
          </Typography>

          <div className={classes.header}>
            <div>
              <div className={classes.headerTime}>
                <DateRangeOutlinedIcon />
                <p>{eventDetails?.eventDate?.toDateString()}</p>
              </div>

              <Typography>Mode: {eventDetails?.eventMode}</Typography>
            </div>
            <div>
              <ViewEventButton
                onClick={(e) => handlerOpenEvent(e, eventDetails?.formLink)}
              >
                Register
              </ViewEventButton>
            </div>
          </div>
        </div>

        <div>
          <Typography className={classes.shortDesc}>
            {eventDetails?.eventDescription && eventDetails?.eventDescription}
          </Typography>
        </div>

        <div className={classes.eventPoster}>
          <img
            height={365}
            width={1279}
            src={eventDetails?.eventPoster}
            alt=""
            className={classes.poster}
          />
        </div>
        <div className={classes.longDesc}>
          {eventDetails?.longDescription &&
            parse(eventDetails?.longDescription)}
        </div>
      </div>
    </>
  );
};
