import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Footer } from "../Home/Footer";
import Navbar from "../../components/Navbar";
import { useEffect } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";
import { getUserDetails } from "../../backend/getDataFromFirestore/getUserData";
import { updateUserDetails } from "../../backend/updateDataFromFirebase/updateUser";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "red",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  Btns: {
    width: "200px",
    fontWeight: "bold",
    textTransform: "capitalize",
    color: "#000",
  },
}));

const initialData = {
  studentName: "",
  contactNumber: "",
  parentsContactNumber: "",
  address: "",
  collegeName: "",
  boardName: "",
  bloodGroup: "",
  reference: "",
  emergencyContactName: "",
  emergencyContactNumber: "",
  email: "",
};

export const EditBio = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const docId = localStorage.getItem("docId");
  const [prefilled, setPrefilled] = useState(initialData);
  const [formData, setFormData] = useState(initialData);
  const [reference, setReference] = useState("");

  useEffect(() => {
    (async () => {
      const { data } = await getUserDetails(docId);
      setFormData(data);
      setPrefilled(data);
    })();
  }, [docId]);

  const changeReference = (e) => {
    const { value } = e?.target;
    setReference(value);
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;

    setFormData({ ...formData, [`${name}`]: value });

    // if (name === "reference") {
    //   if (value !== "others") {
    //     setReference("");
    //   } else {
    //     setReference(value);
    //   }
    // }
  };

  const handleUpdateDetails = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      reference:
        formData.reference !== "other" ? formData.reference : reference,
    };

    const { successful } = await updateUserDetails(payload);
    if (successful) {
      toast.success("Updated Successfully");
      navigate("/home");
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <Container>
        <Main>
          <Header>
            <div>
              <h3>EDIT BIO</h3>
            </div>
          </Header>
          <Form>
            <div>
              <p>Student’s Personal Information</p>
            </div>

            <form onSubmit={handleUpdateDetails}>
              <>
                <InputFields>
                  <div>
                    <label htmlFor="studentName">
                      Name <span>*</span>
                    </label>
                    <TextField
                      placeholder="Enter Student’s Name"
                      variant="outlined"
                      type="text"
                      id="studentName"
                      name="studentName"
                      disabled
                      onChange={handleChange}
                      value={formData.studentName}
                      autoComplete="off"
                    />
                  </div>

                  <div>
                    <label htmlFor="contactNumber">
                      Contact No.<span>*</span>
                    </label>
                    <TextField
                      autoComplete="off"
                      placeholder="Enter Contact No."
                      variant="outlined"
                      name="contactNumber"
                      disabled
                      onChange={handleChange}
                      value={+formData?.contactNumber}
                      type="number"
                      id="contactNumber"
                    />
                  </div>

                  <div>
                    <label htmlFor="parentsContactNumber">
                      Parent’s Contact No.
                    </label>
                    <TextField
                      autoComplete="off"
                      id="parentsContactNumber"
                      placeholder="Enter Parent’s Contact No."
                      variant="outlined"
                      name="parentsContactNumber"
                      disabled={prefilled.parentsContactNumber}
                      onChange={handleChange}
                      value={formData.parentsContactNumber}
                      type="number"
                    />
                  </div>
                </InputFields>

                <InputFields>
                  <div style={{ flexBasis: "80%" }}>
                    <label htmlFor="">Address</label>
                    <TextField
                      autoComplete="off"
                      id="address"
                      placeholder="Enter Student’s Address"
                      variant="outlined"
                      type="text"
                      disabled={prefilled.address}
                      onChange={handleChange}
                      value={formData.address}
                      name="address"
                    />
                  </div>
                </InputFields>

                <InputFields>
                  <div style={{ flexBasis: "33%" }}>
                    <label htmlFor="collegeName">School/College Name</label>
                    <TextField
                      autoComplete="off"
                      id="collegeName"
                      placeholder="School/College Name"
                      variant="outlined"
                      name="collegeName"
                      disabled={prefilled.collegeName}
                      onChange={handleChange}
                      value={formData.collegeName}
                      type="text"
                    />
                  </div>

                  <div style={{ flexBasis: "33%" }}>
                    <label htmlFor="board">Board</label>
                    <FormControl
                      variant="outlined"
                      disabled={prefilled.boardName}
                      className={classes.formControl}
                    >
                      <Select
                        id="board"
                        name="boardName"
                        onChange={handleChange}
                        value={formData.boardName}
                        autoComplete="off"
                      >
                        <MenuItem value="IGCSE">
                          International General Certificate of Secondary
                          Education (IGCSE)
                        </MenuItem>
                        <MenuItem value="ICSE">
                          Indian Certificate of Secondary Education (ICSE)
                        </MenuItem>
                        <MenuItem value="CBSE">
                          Central Board of Secondary Education (CBSE)
                        </MenuItem>
                        <MenuItem value="HSC">
                          Higher Secondary School Certificate (HSC)
                        </MenuItem>
                        <MenuItem value="SSC">
                          Secondary School Certificate (SSC)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div style={{ flexBasis: "33%" }}>
                    <label htmlFor="blood">Blood Group</label>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      disabled={prefilled.bloodGroup}
                    >
                      <Select
                        id="blood"
                        name="bloodGroup"
                        onChange={handleChange}
                        value={formData.bloodGroup}
                        autoComplete="off"
                      >
                        {BLOOD_GROUP.map((item, index) => (
                          <MenuItem value={item.group}>{item.group}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </InputFields>

                <InputFields>
                  <div>
                    <label htmlFor="reference">Reference</label>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      disabled={prefilled.reference}
                    >
                      <Select
                        autoComplete="off"
                        name="reference"
                        onChange={handleChange}
                        id="reference"
                        value={formData.reference}
                      >
                        <MenuItem value="Instagram">Instagram</MenuItem>
                        <MenuItem value="Facebook">Facebook</MenuItem>
                        <MenuItem value="Google">Google</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div>
                    <label htmlFor="emergencyContactName">
                      Emergency Contact Name
                    </label>
                    <TextField
                      autoComplete="off"
                      id="emergencyContactName"
                      placeholder="Emergency Contact Name"
                      variant="outlined"
                      type="text"
                      name="emergencyContactName"
                      disabled={prefilled.emergencyContactName}
                      onChange={handleChange}
                      value={formData.emergencyContactName}
                    />
                  </div>

                  <div>
                    <label htmlFor="emergencyContactNumber">
                      Emergency Contact Number
                    </label>
                    <TextField
                      autoComplete="off"
                      id="emergencyContactNumber"
                      placeholder="Enter Contact Number"
                      variant="outlined"
                      type="number"
                      name="emergencyContactNumber"
                      disabled={prefilled.emergencyContactNumber}
                      onChange={handleChange}
                      value={formData.emergencyContactNumber}
                    />
                  </div>
                </InputFields>
                <InputFields>
                  {formData.reference === "Others" ? (
                    <div>
                      <label htmlFor="studentEmail">Other Reference</label>
                      <TextField
                        autoComplete="off"
                        id=""
                        style={{ width: 240 }}
                        placeholder="Enter Other Reference"
                        variant="outlined"
                        type="text"
                        name="otherReference"
                        disabled={prefilled.reference}
                        onChange={changeReference}
                        value={reference}
                      />
                    </div>
                  ) : null}
                  <div>
                    <label htmlFor="studentEmail">
                      Email <span>*</span>
                    </label>
                    <TextField
                      style={{ width: 504 }}
                      placeholder="Enter Student’s Email"
                      variant="outlined"
                      type="text"
                      id="studentEmail"
                      name="email"
                      disabled
                      onChange={handleChange}
                      value={formData.email}
                      autoComplete="off"
                    />
                  </div>
                </InputFields>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#F6B501" }}
                  className={classes.Btns}
                  type="submit"
                >
                  Update
                </Button>
              </>
            </form>
          </Form>
        </Main>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

const Container = styled.div`
  background-color: #fff;
  width: 100%;
`;

const Main = styled.div`
  width: 100%;
  max-width: 1250px;
  margin: 30px auto;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #808080;
  > div {
    h3 {
      font-size: 18px;
      margin-top: 5px;
    }
  }

  p {
    color: #666666;
    color: #666666;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  span {
    color: #1a1a1a;
    font-size: 15px;
  }
  svg {
    height: 15px;
  }
`;

const Form = styled.div`
  margin-top: 10px;

  > div > p {
    color: #000;
    padding-bottom: 10px;
    font-weight: 550;
    border-bottom: 1px solid #ccc;
  }

  form {
    > div {
      margin-top: 10px;
    }
  }
`;

const InputFields = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;

  label {
    span {
      color: red;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    > input {
      padding: 15px 10px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 5px;
      margin-top: 5px;
    }
    select {
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 5px;
      margin-top: 5px;
      width: 100%;
    }
  }
`;

const BLOOD_GROUP = [
  {
    id: 1,
    group: "A+",
  },
  {
    id: 2,
    group: "A-",
  },
  {
    id: 3,
    group: "B+",
  },
  {
    id: 4,
    group: "B-",
  },

  {
    id: 5,
    group: "AB+",
  },
  {
    id: 6,
    group: "AB-",
  },
  {
    id: 7,
    group: "O+",
  },
  {
    id: 8,
    group: "O-",
  },
];
