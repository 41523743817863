import React from "react";
import Section from "./WebDevSection";
import webDevWhy from '../../assets/WebDevPage/web-dev-why.png'
import details from '../../assets/WebDevPage/details-2.png'

function WebDevPromo() {
  return (
    <div className=" w-full pb-12">
      <div className=" max-w-7xl mx-auto h-full  px-4 md:px-6">
      <Section 
          title="Why Get A Website?" 
          items={[
            {
              text: "93% Of Business Purchase Decision Start With A Search Engine Search",
              highlight: true
            },
            "Building Credibility With A Website - In Addition To Showcasing Your Products And Services You Can Also Tell Consumers About Your Company And It's Mission",
            "Providing Accessibility Around The Clock - With Website You Provide A Place For Consumers To See Your Brand 24/7",
            "Market Expansion - Anyone From Anywhere In The World Will Be Able To Find Your Company",
            "Improves Customer Service - Information Requests Can Be Processed Immediately Via Online Form And Auto-responder Automatically Day Or Night",
            "Save Money - Cheap Space, Low-cost Maintenance",
          ]}
          imageSrc= {webDevWhy}
        />
        <Section 
          title="Why Choose Rogue Code?" 
          items={[
            "We Have Built Multiple Websites And Tens Of Positive Reviews From Our Satisfied Clients",
            "Update Your Website With The Latest Technologies",
            "Our Team Of Professionals Are Ready To Help You To Take That Leap In The Online World",
          ]}
          imageSrc= {details}
          imageLeft={true}
        />
      </div>
    </div>
  );
}

export default WebDevPromo;
