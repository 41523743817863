import React from "react";
import AnimationWrapper from "../../common/AnimationWrapper";
import { Tldraw } from "@tldraw/tldraw";

function Jamboard() {
  return (
    <AnimationWrapper title="Jamboard">
      <div style={{ position: "fixed", inset: 0 }}>
        <Tldraw darkMode={false} persistenceKey="roguecode" />
      </div>
    </AnimationWrapper>
  );
}

export default Jamboard;
