import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { CourseCard } from "./CourseCard";

export const CourseList = ({ courses }) => {
  return (
    <React.Fragment>
      <Grid container spacing={5}>
        {courses?.length ? (
          courses?.map((course, index) => {
            return (
              <Grid key={course?.courseId} item xs={12} sm={6} md={6} lg={4}>
                <CourseCard course={course} />
              </Grid>
            );
          })
        ) : (
          <Typography variant="h4">
            Admin is working on your batch and courses, it will updated soon
          </Typography>
        )}
      </Grid>
    </React.Fragment>
  );
};
