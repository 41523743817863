import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import db from "../firebase";

export const addQuizAttempts = async (data) => {
  const userId =
    localStorage.getItem("userUID") ?? localStorage.getItem("docId");
  const userName = localStorage.getItem("name");

  try {
    const attemptsRef = query(
      collection(db, "Attempts"),
      where("quizId", "==", data?.quizId)
    );
    const querySnapshot = await getDocs(attemptsRef);
    const totalDocs = querySnapshot.size + 1 ?? 1;

    const docRef = collection(db, "Attempts");
    await addDoc(docRef, {
      ...data,
      userId,
      userName,
      attempts: totalDocs,
    });
    return { successful: true };
  } catch (error) {
    console.log(error);
  }
};
