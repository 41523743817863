import db from "../firebase";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";

export const getProducts = async (id) => {
  const products = [];
  try {
    const productRef = query(collection(db, "Products"));
    const querySnapshot = await getDocs(productRef);
    querySnapshot.forEach((doc) => {
      products.push(doc.data());
    });
    return { successful: true, data: products };
  } catch (error) {
    return { successful: false, data: "There is something wrong" };
  }
};

export const getProductDetails = async (id) => {
  const productRef = doc(db, "Products", id);
  const querySnapshot = await getDoc(productRef);
  const data = querySnapshot.data();
  return data;
};
