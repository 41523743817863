import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { getProducts } from "../../backend/shopTab";
import { ProductCard } from "../../pages/CoreShopTab/CoreProductsTab/ProductCard";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "35px 0px",
  },
  heading: {
    paddingBottom: 60,
    fontWeight: "700",
    display: "flex",
    justifyContent: "center",
  },
}));

export const SimilarProducts = () => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    handleProducts();
  }, []);

  const handleProducts = async () => {
    const res = await getProducts();
    if (res.successful) {
      setProducts(res.data);
    }
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.heading} variant="h4">
        Similar Product
      </Typography>
      <Grid container spacing={5}>
        {products?.length ? (
          products?.slice(0, 5).map((product, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <ProductCard products={product} />
              </Grid>
            );
          })
        ) : (
          <Typography variant="h4">Not found</Typography>
        )}
      </Grid>
    </div>
  );
};
