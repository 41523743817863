import React from "react";

function Section({ title, items, imageSrc, imageLeft = false }) {
  return (
    <section className="my-8">
      <div
        className={`flex flex-col md:flex-row items-center ${
          imageLeft ? "md:flex-row-reverse" : ""
        }`}
      >
        <div className={`md:w-2/3 `}>
          <h2 className="text-4xl font-semibold mb-4 text-blue-700">{title}</h2>
          <ul className="space-y-2">
            {items.map((item, index) => (
              <li key={index} className="flex items-start space-x-2">
                <span className="text-yellow-500 mt-1">⚡</span>
                <p className={item.highlight ? "font text-3xl " : ""}>
                  {item.text || item}
                </p>
              </li> 
            ))}
          </ul>
        </div>
        <div className={`md:w-1/3 mt-4 md:mt-0`}>
          <img src={imageSrc} alt={title} className="w-full" />
        </div>
      </div>
    </section>
  );
}

export default Section;
