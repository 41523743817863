import db from "../firebase";
import { doc, getDoc } from "firebase/firestore";

export const getBlog = async (id) => {
  const docRef = doc(db, "Blogs", id);
  const docSnap = await getDoc(docRef);
  console.log(docSnap.data());

  if (!docSnap.exists()) {
    return null;
  }
  return docSnap.data();
};
