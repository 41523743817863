import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { INPUT_IMAGE_PATH } from "../../common/const";
import { navbarList } from "../../common/const";
import { Link, useLocation } from "react-router-dom";
import Hamburger_Icon from "../../assets/Hamburger_icon.svg";
import ProductContext from "../../components/Context/ProductContext";
import { Badge } from "@material-ui/core";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

const useStyles = makeStyles(() => ({
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    paddingLeft: 20,
  },

  itemList: {
    display: "flex",
    listStyle: "none",
    "& a": {
      padding: "15px 20px",
      cursor: "pointer",
      fontWeight: 500,
      margin: 0,
      color: "#000",

      "&:last-child": {
        background: "#5221E6 !important",
        color: "#fff !important",
      },
    },
  },

  links: {
    textDecoration: "none",
    color: "inherit",

    cursor: "pointer",
    margin: 0,
    padding: "15px 20px",
    fontWeight: 500,

    "&:hover": {
      background: "#000 !important",
      color: "#fff !important",
    },
  },
}));

const LoginNavbar = () => {
  const classes = useStyles();
  const { cartData } = useContext(ProductContext);
  const { pathname } = useLocation();
  const [showSidebar, setShowSidebar] = useState(
    window.matchMedia("(min-width: 600px)").matches
  );

  const toggleSidebar = () => {
    setShowSidebar((state) => !state);
  };

  return (
    <>
      <div className={`${classes.navbar} max-sm:flex-col max-sm:items-stretch`}>
        <div className="max-sm:flex max-sm:justify-between max-sm:p-5">
          <div className={classes.Logo}>
            <img src={INPUT_IMAGE_PATH.LOGIN_ROGUE} alt="" />
          </div>
          <img
            className="sm:hidden"
            onClick={toggleSidebar}
            src={Hamburger_Icon}
            alt="Hamburger_Icon"
          />
        </div>
        {showSidebar && (
          <div>
            <ul className={`${classes.itemList} max-sm:flex-col`}>
              {pathname.includes("shop") ? <Link
                to={"/shopping-cart"}
              >
                <Badge badgeContent={cartData?.length} color="primary">
                  <ShoppingCartOutlinedIcon />
                </Badge>
              </Link> : null}
              <a
                target="_blank"
                rel="noopener noreferrer"
                key={"https://rc-js-compiler.netlify.app/"}
                className={classes.links}
                href={"https://rc-js-compiler.netlify.app/"}
              >
                Online Compiler
              </a>
              {navbarList.map((item, index) => {
                return (
                  <Link
                    style={{
                      background: item.link === pathname ? "#000" : "#fff",
                      color: item.link === pathname ? "#fff" : "#000",
                    }}
                    key={index}
                    className={classes.links}
                    to={item.link}
                  >
                    {item.name}
                  </Link>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default LoginNavbar;
