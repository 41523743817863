import React from "react";

const skills = [
  { name: "HTML5", value: 100 },
  { name: "CSS3", value: 90 },
  { name: "JavaScript", value: 85 },
  { name: "PHP & MySQL", value: 80 },
  { name: "WordPress/CMS", value: 90 },
  { name: "ReactJS", value: 85 },
];

function WebDevSkills() {
  return (
    <div className=" w-full pb-12 relative">
      <div className=" max-w-7xl mx-auto h-full  px-4 md:px-6">
        <div className="section-title pt-8">
          <h2 className="">Skills</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 skills-content">
          {skills.map((skill, index) => (
            <div key={index} className="progress mb-4">
              <span className="skill flex justify-between items-center text-md font-normal mb-2">
                {skill.name} <i className="val">{skill.value}%</i>
              </span>
              <div className="progress-bar-wrap bg-gray-300 h-2 rounded">
                <div
                  className="progress-bar h-2 bg-yellow-500 rounded"
                  style={{ width: `${skill.value}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WebDevSkills;
