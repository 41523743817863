import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const QuizCardButton = withStyles(() => ({
  root: {
    backgroundColor: "#F6B501",
    height: 46,
    width: "100%",
    fontWeight: 600,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#F6B501",
      boxShadow: "none",
    },
  },
}))(Button);

export const StartQuizButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#18A136",
    width: "101px",
    fontWeight: 500,
    color: "#fff",
    boxShadow: "none",
    height: 46,
    "&:hover": {
      backgroundColor: "#18A136",
      boxShadow: "none",
    },
  },
}))(Button);

export const PptButton = withStyles((theme) => ({
  root: {
    height: 46,
    fontWeight: 500,
    boxShadow: "none",
    color: "#fff",
    fontFamily: theme.typography.fontFamily,
    borderRadius: 6,
    width: "100%",
  },
}))(Button);

export const FilterButton = withStyles((theme) => ({
  root: {
    height: 36,
    borderRadius: 14,
    border: "1px solid #FFFFFF",
    background:
      "linear-gradient(0deg, rgba(92, 54, 187, 0.4), rgba(92, 54, 187, 0.4)),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    textTransform: "capitalize",
    color: theme.palette.common.white,
    fontSize: 14,
    padding: "0px 38px",
    boxShadow: "none",
  },
}))(Button);

export const ProductButton = withStyles((theme) => ({
  root: {
    height: 69,
    width: 284,
    borderRadius: 4,
    border: "3px solid #5C36BB",
    textTransform: "none",
    fontSize: 20,
    boxShadow: "none",
  },
}))(Button);

export const EventButton = withStyles((theme) => ({
  root: {
    height: 42,
    width: 530,
    borderRadius: 4,
    textTransform: "none",
    fontSize: 20,
    boxShadow: "none",
    background: "#5C36BB",
    color: "#fff",

    "&:hover": {
      background: "#5C36BB",
      boxShadow: "none",
    },
  },
}))(Button);

export const ViewEventButton = withStyles((theme) => ({
  root: {
    height: 60,
    width: 200,
    borderRadius: 4,
    textTransform: "none",
    fontSize: 18,
    boxShadow: "none",
    background: "#002E63",
    color: "#fff",

    "&:hover": {
      background: "#002E63",
      boxShadow: "none",
    },
  },
}))(Button);
