import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC8PvBvetx2C_uyRP_xaSfZQ5gi1Zvs_aQ",
  authDomain: "rogue-code-portal.firebaseapp.com",
  projectId: "rogue-code-portal",
  storageBucket: "rogue-code-portal.appspot.com",
  messagingSenderId: "750751944799",
  appId: "1:750751944799:web:46b83bb5d402e187238bea",
  measurementId: "G-W0QLYJDGSZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { storage };

export default db;
