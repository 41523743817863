import React from "react";
import { CourseDetailsHeader } from "./CourseDetailsHeader";
import { CourseDetailsCard } from "./CourseDetailsCard";
import Navbar from "../../components/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { Quizzes } from "./Quizes/Quizzes";
import { Footer } from "../Home/Footer";
import { Assignments } from "./Assignments/Assignments";
import { Syllabus } from "./Syllabus/Syllabus";
import { Ppt } from "./PPT/Ppt";
import { VideoList } from "./Videos/VideoList";

const useStyles = makeStyles(() => ({
  main: {
    padding: "40px 50px",
    background: "#EEEE",
  },
  divider: {
    width: "100%",
    maxWidth: 650,
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      borderBottom: "1px solid #ccc",
    },
    "& .MuiPaper-root": {
      background: "transparent",
    },
  },
  Tabs: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
    },
  },
  tabHeader: {
    "& .MuiTab-wrapper": {
      display: "-webkit-box",
    },
    "& .MuiTab-labelIcon": {
      minHeight: "0px",
    },
    "& .MuiTab-root": {
      minWidth: 0,
      marginRight: "11px",
    },
    "& .MuiTypography-body1": {
      fontWeight: 600,
    },
  },
}));

const CORE_TAB_NAMES = [
  {
    tabName: "SYLLABUS",
    tabIcon: <MenuBookIcon />,
  },
  {
    tabName: "QUIZZES",
    tabIcon: <FileCopyIcon />,
  },
  {
    tabName: "ASSIGNMENTS",
    tabIcon: <AssignmentIcon />,
  },
  {
    tabName: "PPT",
    tabIcon: <PersonPinIcon />,
  },
  {
    tabName: "Videos",
    tabIcon: <VideoLibraryIcon />,
  },
];

export const CourseDetails = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [renderComponent, setRenderComponent] = React.useState(value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setRenderComponent(newValue);
  };

  return (
    <React.Fragment>
      <Navbar />
      <CourseDetailsHeader />
      {/* <CourseDetailsCard /> */}
      <div className={classes.main}>
        <div className={classes.divider}>
          <Paper square>
            <Tabs
              value={value}
              textColor="primary"
              onChange={handleChange}
              indicatorColor="primary"
              className={classes.tabHeader}
            >
              {CORE_TAB_NAMES?.map((tab) => (
                <Tab
                  component="a"
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  textColor="primary"
                  label={<Typography>{tab.tabName}</Typography>}
                  icon={tab.tabIcon}
                  className={classes.Tabs}
                />
              ))}
            </Tabs>
          </Paper>
        </div>
        <div style={{ paddingTop: 20 }}>
          <RenderComponent renderComponent={renderComponent} />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

const RenderComponent = ({ renderComponent }) => {
  switch (renderComponent) {
    case 0:
      return <Syllabus />;
    case 1:
      return <Quizzes />;
    case 2:
      return <Assignments />;
    case 3:
      return <Ppt />;
    case 4:
      return <VideoList/>;
    default:
      return;
  }
};
