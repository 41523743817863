import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { getQuizAttemptsData } from "../backend/getData";

const Attempts = () => {
  const [attemptData, setAttempts] = useState([]);
  const [search, setSearch] = useState({
    name: "",
  });

  const getData = async () => {
    const totalAttemptsData = await getQuizAttemptsData();
    setAttempts(totalAttemptsData ?? []);
  };

  useEffect(() => {
    getData();
  }, []);

  const handlerInput = (e) => {
    setSearch(e.target.value);
    const quiz = e.target.value;
    if (quiz === "") {
      getData();
    } else {
      const filterData = attemptData.filter((item) => {
        return item.quizName.toLowerCase().includes(quiz.toLowerCase());
      });
      setAttempts(filterData);
    }
  };
  console.log(attemptData);

  return (
    <Container>
      <Navbar />
      <Attempt>
        <Header>
          <div>
            <h2>Total Attempts</h2>
            <p>Below is the list of total attempts by you.</p>
          </div>

          <Inputs>
            <label htmlFor="name">Sort By</label>
            <input
              type="text"
              placeholder="Enter quiz name..."
              id="name"
              name="name"
              value={search.name}
              onChange={handlerInput}
            />
          </Inputs>
        </Header>
      </Attempt>

      <Main>
        <Box>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Quiz Name</th>
                <th>Attempts</th>
                <th>Score</th>
                <th>Time Taken</th>
                <th>Date Of Quiz Taken</th>
                <th>Result</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {attemptData?.map((item, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.quizName.toUpperCase()}</td>
                      <td>{item.attempts}</td>
                      <td>
                        {item.score}/{item.quizLength}
                      </td>
                      <td>
                        {item.sec === 60 ? (
                          <>{item.min + 1} : 00 </>
                        ) : (
                          <>
                            {item.min} : {item.sec}
                          </>
                        )}
                      </td>
                      <td>{item.date}</td>

                      <td>
                        {item.score >
                        Math.floor((item.quizLength * 40) / 100) ? (
                          <BtnO>PASS</BtnO>
                        ) : (
                          <BtnT>FAIL</BtnT>
                        )}
                      </td>
                      <td>
                        <Btns>
                          <Link to={`/${item.quizName}`}>RETAKE TEST</Link>
                        </Btns>
                      </td>
                    </tr>
                    <Break />
                  </>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Main>
    </Container>
  );
};

const Break = styled.div`
  padding: 6px;
`;

const Container = styled.div`
  /* background-color: red; */
  width: 100%;
  height: 100vh;
  position: relative;
  background: #e6e2e2;
`;

const Attempt = styled.div`
  color: #fff;
  padding: 20px 20px;
  background: #5c36bb;
  height: 100%;
  max-height: 250px;

  h2 {
    font-size: 40px;
  }
  p {
    font-size: 20px;
    line-height: 1.8;
  }
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  input {
    padding: 10px;
    outline: none;
    border: 1px solid #cccccc;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    &::placeholder {
      color: #fff;
    }
  }
  label {
    position: absolute;
    background-color: #5c36bb;
    top: -10px;
    left: 10px;
    font-size: 12px;
    padding: 3px 5px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  /* background-color: red; */
  margin-top: 50px;
`;

const Main = styled.div`
  /* background-color: yellowgreen; */
  /* height: 200px; */
  margin-top: -50px;
`;

const BtnO = styled.button`
  background: #bbffba;
  border-radius: 999px;
  padding: 5px 10px;
  border: none;
  outline: none;
  color: #219653;
`;

const BtnT = styled(BtnO)`
  background: #fcc8c8;
  color: #f44336;
`;

const Box = styled.div`
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  /* margin-top: -50px; */
  height: 450px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    text-align: center;

    thead {
      background-color: #5c36bb;
      position: sticky;
      top: 0;
    }
  }

  th,
  td {
    padding: 10px;
  }

  th {
    color: #fff;
  }

  td {
    background-color: #fff;
    margin: 20px;
  }

  tr {
    /* position: relative; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 20px;
    &:first-child {
      box-shadow: none;
    }
  }
`;

const Btns = styled.div`
  a {
    text-decoration: none;
    color: #fff;
    padding: 5px 8px;
    background: red;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 5px;
    background: #5c36bb;
    cursor: pointer;
    font-size: 13px;
  }
`;

export default Attempts;
