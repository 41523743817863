import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PerCourseData } from "../../backend/PerCourseData";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 160,
    borderRadius: 0,
    background: "#5C36BB",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
  },
  container: {
    display: "flex",
    gap: 31,
    maxWidth: 784,
    paddingLeft: 50,
  },
  logo: {
    objectFit: "cover",
  },
}));

export const CourseDetailsHeader = () => {
  const classes = useStyles();

  const [courseData, setCourseData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getCourseData(id);
  }, [id]);

  const getCourseData = async (id) => {
    const result = await PerCourseData(id);
    setCourseData(result);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img
          height={95}
          width={95}
          src={courseData?.CourseLogo}
          alt={"courseLogo"}
          className={classes.logo}
        />
        <div>
          <Typography
            variant="h4"
            style={{ fontWeight: "600", paddingBottom: 8 }}
          >
            {courseData?.courseTitle}
          </Typography>
          <Typography variant="h6">{courseData?.courseDescription}</Typography>
        </div>
      </div>
    </div>
  );
};
