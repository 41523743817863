import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { getBlog } from "../../backend/fetchSingleBlog";
import BlogDescription from "./BlogDescription";
import BlogHeader from "./BlogHeader";
import LoginNavbar from "../../pages/Home/LoginNavbar";

const useStyles = makeStyles(() => ({
  continer: {
    width: "100%",
    maxWidth: 1200,
    margin: "0 auto",
    marginTop: 100,
    marginBottom: 20,
  },
}));

const BlogsView = () => {
  const [blogData, setBlogs] = useState({});
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    handlerFetchBlog();
  }, [location]);

  const handlerFetchBlog = async () => {
    const blogId = location.pathname.split("/").splice(2).join("");
    const res = await getBlog(blogId);
    setBlogs(res);
  };

  return (
    <>
      <LoginNavbar />
      <div className={classes.continer}>
        <BlogHeader blogData={blogData} />
        <BlogDescription blogData={blogData} />
      </div>
    </>
  );
};

export default BlogsView;
