import React, { useEffect } from "react";
import LoginNavbar from "./LoginNavbar";
import trainingImg from "../../assets/homepage/training.jpeg";
import shopImg from "../../assets/homepage/shop.jpeg";
import webDevImg from "../../assets/homepage/web-dev.jpeg";
import blogImg from "../../assets/homepage/blog.avif";
import eventsImg from "../../assets/homepage/events.jpeg";

import "./homepage.css";
import { infiniteCardScroll } from "./homepage";
import AnimationWrapper from "../../common/AnimationWrapper";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    infiniteCardScroll();
  }, []);

  return (
    <AnimationWrapper>
      <div className="parent-container">
        <div className="container-wrapper">
          <LoginNavbar />
        </div>

        <div className="gallery">
          <ul className="cards">
            {cardsData.map((data, index) => (
              <li key={index}>
                <Card data={data} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </AnimationWrapper>
  );
};

const cardsData = [
  {
    title: "Training",
    image: trainingImg,
    link: "/training",
  },
  {
    title: "Web Dev",
    image: webDevImg,
    link: "/web-dev",
  },
  {
    title: "Blog",
    image: blogImg,
    link: "/blogs",
  },
  {
    title: "Events",
    image: eventsImg,
    link: "/events",
  },
  {
    title: "Shop",
    image: shopImg,
    link: "/shop",
  },
];

const Card = ({ data }) => {
  const { title, image, link } = data;

  const isAbsoluteUrl = link?.startsWith("http");

  if (isAbsoluteUrl) {
    return (
      <div className="box">
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img className="image-wrapper" src={image} alt={title} />
          <p className="card-heading">{title}</p>
        </a>
      </div>
    );
  }

  return (
    <div className="box">
      <Link to={link} target="_blank" rel="noopener noreferrer">
        <img className="image-wrapper" src={image} alt={title} />
        <p className="card-heading">{title}</p>
      </Link>
    </div>
  );
};

export default Home;
