import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CompletedQuiz = (props) => {
  const {
    score,
    submitQuiz,
    handlerSubmitQuiz,
    quizLength,
    minutes,
    handlerView,
  } = props;
  const navigate = useNavigate();

  const handlerHome = () => {
    navigate("/home");
  };

  const handlerPlay = () => {
    handlerSubmitQuiz();
  };

  return (
    <>
      {submitQuiz === "open" && (
        <Container>
          <Box>
            {minutes === 0 ? (
              <Sad>
                <h1 style={{ color: "red" }}>TIME'S UP ⏰ </h1>
              </Sad>
            ) : score === 0 ? (
              <h1 style={{ color: "#808080" }}>Better luck next time!!! 😥</h1>
            ) : (
              <h1 style={{ color: "#F2B318" }}>CONGRATULATIONS 🎉</h1>
            )}

            <h3>You answer</h3>
            <Accuracy>
              <h2>
                {score}/{quizLength}
              </h2>
            </Accuracy>
            <h3>questions correctly</h3>

            <Btns>
              <button onClick={handlerPlay}>Play Again</button>

              <button onClick={handlerHome}>Back to Home</button>
            </Btns>
            <View>
              <button onClick={() => handlerView()}>View Solution</button>
            </View>
          </Box>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  animation: fadeIn 0.3s ease-in;
`;

const Box = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 550px;
  padding: 40px 0;
  border-radius: 10px;
  text-align: center;
  h1 {
    /* color: #00D35A;; */
    text-align: center;
  }
  h3 {
    color: #666666;
    font-weight: 400;
    font-size: 18px;
  }
  h2 {
    color: #f2b318;
  }
`;

const Sad = styled.div`
  color: #808080;
  h1 {
    color: #808080;
  }
`;

const Accuracy = styled.div`
  font-weight: 500;
  font-size: 15px;
`;

const Btns = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;

  button {
    outline: none;
    border: none;
    padding: 15px 65px;
    background: #f2b318;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;

    &:nth-child(2) {
      background: #1a1a1a;
      color: #fff;
    }
  }
`;

const View = styled.div`
  margin-top: 15px;
  /* background-color: red; */

  button {
    outline: none;
    border: 1px solid #f2b318;
    padding: 15px 130px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    color: #f2b318;
    background: #fff;
  }
`;

export default CompletedQuiz;
