import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import ProductContext from "../../components/Context/ProductContext";

const useStyles = makeStyles({
  quantityInput: {
    width: 125,
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      textAlign: "center",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
    },
  },
  decreaseQuant: {
    paddingRight: 0,
  },
  increaseQuant: {
    paddingLeft: 0,
  },
});

export const EditProductQuantity = ({ quantity, prodId }) => {
  const classes = useStyles();
  const { updateQty } = useContext(ProductContext);
  let [prodQuantity, setValue] = useState(quantity || 1);

  useEffect(() => {
    updateQty(prodId, prodQuantity);
  }, [prodQuantity, prodId]);

  const handleQuantity = (e) => {
    const val = e.target.valueAsNumber;
    const finalVal = isNaN(val) || val <= 1 ? 1 : val;
    setValue(finalVal);
  };

  return (
    <TextField
      size="small"
      className={classes.quantityInput}
      variant="outlined"
      type="number"
      value={prodQuantity}
      onChange={handleQuantity}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              className={classes.decreaseQuant}
              disabled={prodQuantity <= 1}
              onClick={() => setValue(prodQuantity - 1)}
            >
              <RemoveOutlinedIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={classes.increaseQuant}
              onClick={() => setValue(prodQuantity + 1)}
            >
              <AddOutlinedIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
