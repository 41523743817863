import React from "react";
import Navbar from "../../components/Navbar";
import Typography from "@material-ui/core/Typography";
import QuizHeader from "./QuizHeader";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import QuizeSection from "./QuizSection/QuizeSection";
import AssignmenetSection from "./AssignmentSection/AssignmentSection";
import PptSection from "./PptSection/PptSection";
import VideoSection from "./VideoSection/VideoSection";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";

const useStyles = makeStyles(() => ({
  container: {
    background: "#E6E2E2",
    minHeight: "100vh",
  },

  divider: {
    width: "100%",
    maxWidth: 650,
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      borderBottom: "1px solid #ccc",
    },

    "& .MuiPaper-root": {
      background: "transparent",
    },
  },
  main: {
    padding: "15px 25px",
  },
  quizBody: {
    marginTop: 20,
  },
  Tabs: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
    },
  },
  tabHeader: {
    "& .MuiTab-wrapper": {
      display: "-webkit-box",
    },
    "& .MuiTab-labelIcon": {
      minHeight: "0px",
    },
    "& .MuiTab-root": {
      minWidth: 0,
      marginRight: "11px",
    },
    "& .MuiTypography-body1": {
      fontWeight: 600,
    },
  },
}));

const RenderComponent = ({ renderComponent }) => {
  switch (renderComponent) {
    case 0:
      return <QuizeSection />;
    case 1:
      return <AssignmenetSection />;
    case 2:
      return <PptSection />;
    case 3:
      return <VideoSection />;
    default:
      return;
  }
};

const PreviewQuiz = ({ quizName }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [renderComponent, setRenderComponent] = React.useState(value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setRenderComponent(newValue);
  };

  return (
    <>
      <div className={classes.container}>
        <Navbar />
        <QuizHeader />

        <div className={classes.main}>
          <div className={classes.divider}>
            <Paper square>
              <Tabs
                value={value}
                textColor="primary"
                onChange={handleChange}
                indicatorColor="primary"
                className={classes.tabHeader}
              >
                <Tab
                  component="a"
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  textColor="primary"
                  label={<Typography>QUIZZES</Typography>}
                  icon={<FileCopyIcon />}
                  className={classes.Tabs}
                />
                <Tab
                  component="a"
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  textColor="primary"
                  label={<Typography>ASSIGNMENTS</Typography>}
                  icon={<AssignmentIcon />}
                  className={classes.Tabs}
                />
                <Tab
                  component="a"
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  textColor="primary"
                  label={<Typography>PPT</Typography>}
                  icon={<PersonPinIcon />}
                  className={classes.Tabs}
                />
                <Tab
                  component="a"
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  textColor="primary"
                  label={<Typography>Videos</Typography>}
                  icon={<VideoLibraryIcon />}
                  className={classes.Tabs}
                />
              </Tabs>
            </Paper>
          </div>

          <RenderComponent
            renderComponent={renderComponent}
            setRenderComponent={setRenderComponent}
          />
        </div>
      </div>
    </>
  );
};

export default PreviewQuiz;
