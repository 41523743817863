import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EventCard } from "./EventsCard/EventCard";

const useStyles = makeStyles(() => ({
  eventCards: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "0px 30px",
  },
}));

export const ViewEvents = ({ events }) => {
  const classes = useStyles();
  return (
    <div className={classes.eventCards}>
      {events?.map((event) => (
        <EventCard event={event} />
      ))}
    </div>
  );
};
