import React, { useState } from "react";
import { Tabs, Tab, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(6),
  },
  tabList: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  tabContent: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: theme.spacing(4),
  },
  card: {
    width: "100%",
    maxWidth: "300px",
    padding: theme.spacing(2),
    border: "1px solid #ccc",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  cardTitle: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  cardDescription: {
    color: "#555",
    marginBottom: theme.spacing(2),
  },
  cardButton: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[800],
    color: "#fff",
    border: "none",
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
  },
  tabs: {
    minHeight: "unset",
  },
  tab: {
    minHeight: "unset",
    // paddingBottom: theme.spacing(1),
  },
  tabIndicator: {
    height: 2, // Adjust the height of the indicator
  },
}));
const courses = {
  "IN-DEMAND": [
    {
      title: "Python",
      description:
        "Python is a dynamic, interpreted, object-oriented language. It is an open source programming language and Python interpreters are available for many operating systems. It has simple, easy-to-use syntax, making it the perfect language for someone trying to learn computer programming for the first time. It is one of the most in demand in the industry and a introductory language to many other domains including data-science and machine learning.",
    },
    {
      title: "Java",
      description:
        "Java has been the best programming languages created in last 20 years and has been gaining popularity every passing day. You can work on developing core Java based server side applications, can even go for Android based mobile application development , J2EE web and enterprise applications. It is designed for flexibility, allowing developers to write code that would run on any machine, regardless of architecture or platform.",
    },
    {
      title: "SPA/C",
      description:
        "C is the most widely used programming languages of all time. C is a great language for expressing common ideas in programming in a way that most people are comfortable with. The course also includes all the theory questions asked in different University exams along with practical hands-on.C was the basics language to write everything from operating systems (Windows and many others) to complex programs like the Oracle database, Git.",
    },
  ],
  LOGIC: [
    {
      title: "SPA/C",
      description:
        "C is the most widely used programming languages of all time. C is a great language for expressing common ideas in programming in a way that most people are comfortable with. The course also includes all the theory questions asked in different University exams along with practical hands-on.C was the basics language to write everything from operating systems (Windows and many others) to complex programs like the Oracle database, Git.",
    },
    {
      title: "C++",
      description:
        "C++ is our First step towards Object Oriented Programming Methodology. Some of its application domains include systems software, application software and entertainment software.It is the extended part of C. At Rogue Code, Object Oriented Programming is made easy and taught by experienced Professors who have worked in the field of Programming. The course will train student to enhance their programming skills.",
    },
  ],
  "WEB & APP": [
    {
      title: "&",
      description:
        "In this course, you will learn how to design and build beautiful websites by learning the basic principles of design like branding, color theory, and typography which are all instrumental in the design process of a website. You’ll also learn HTML and CSS, which are the common code languages that all modern websites are built on. These are useful skills to acquire as they are needed by nearly every single business in the world to communicate to customers.",
    },
    {
      title: "Android",
      description:
        "Android is a software package and Linux based Operating System for mobile devices such as tablets, computers and smartphones. It was developed by Google and later the OHA (Open Handset Alliance). Android programming is based on Java programming language. A sound knowledge of JAVA basics is important to start with Android. At Rogue Code along with Android Studio, Flutter is also introduced in the course.",
    },
  ],
  DESIGN: [
    {
      title: "Graphic Designing",
      description:
        "Graphics Web Design is a comprehensive program that trains you in all aspects of graphic design.The course utilizes industry-supported procedures to make you a popular new media imaginative expert with abilities in rich sight and sound substance, outwardly engaging sites, logos for promotions, ideas of digital graphics, picture altering for print and publishing UI/UX, SEO, and Content Management.",
    },
  ],
  KIDS: [{ title: "KIDS SECTION", description: "" }],
};

function TrainingTab() {
  const classes = useStyles();
  const [value, setValue] = useState("IN-DEMAND");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="py-24">
      <div className="mx-auto container">
        <div className="section-title">
          <h2 className="text-3xl">Training</h2>
        </div>

        <div
          className={`${classes.container} flex flex-col items-center py-2 md:py-6 px-2 md:px-6`}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            classes={{ indicator: classes.tabIndicator }}
          >
            <Tab
              label="IN-DEMAND"
              value="IN-DEMAND"
              className={classes.tabList}
            />
            <Tab label="LOGIC" value="LOGIC" className={classes.tabList} />
            <Tab
              label="WEB & APP"
              value="WEB & APP"
              className={classes.tabList}
            />
            <Tab label="KIDS" value="KIDS" className={classes.tabList} />
            <Tab label="DESIGN" value="DESIGN" className={classes.tabList} />
          </Tabs>

          <Box className={classes.tabContent}>
            {Object.keys(courses).map(
              (category) =>
                value === category && (
                  <div
                    key={category}
                    className="flex flex-wrap justify-center gap-8"
                  >
                    {courses[category].map((course, index) => (
                      <div key={index} className={classes.card}>
                        <h2 className={classes.cardTitle}>{course.title}</h2>
                        <p className={classes.cardDescription}>
                          {course.description}
                        </p>
                        <button className={classes.cardButton}>
                          Know More
                        </button>
                      </div>
                    ))}
                  </div>
                )
            )}
          </Box>
        </div>
      </div>
    </div>
  );
}

export default TrainingTab;
