import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: 16,
    boxShadow: "0px 3.4721407890319824px 6.944281578063965px 0px #00000033",
    backgroundColor: "#FFF",
    overflow: "hidden",
    textAlign: "left",
    cursor: "pointer",
  },
  container: {
    width: 330,
  },

  media: {
    height: 160,
    width: "100%",
  },

  blogImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  content: {
    height: 180,
    padding: "20px 15px",
  },

  chip: {
    color: "#5C36BB",
    padding: "0px 6px",
    fontWeight: 600,
    fontSize: 12,
    border: "1px solid #5C36BB",
  },

  chips: {
    display: "flex",
    gap: 10,
  },
  Header: {
    fontWeight: 600,
    fontSize: 21,
    margin: "5px 0px",
  },

  readingTime: {
    fontWeight: 300,
    fontSize: 15,
  },

  blogTitle: {
    fontWeight: "600",
    color: "#000",
  },

  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
  },

  leftFooter: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  dateTime: {
    fontSize: 13,
    marginLeft: 3,
    color: "#000",
  },
}));

const BlogsBox = ({ item }) => {
  const classes = useStyles();

  const blogRef = useRef(null);
  useEffect(() => {
    blogRef.current.innerHTML = item?.blogDescription?.slice(0, 40);
  }, [item]);

  function convertTimestamp(updatedDate) {
    let date = updatedDate?.toDate();
    let mm = date?.getMonth();
    let dd = date?.getDate();
    let yyyy = date?.getFullYear();
    let hh = date?.getHours();
    let min = date?.getMinutes();
    let sec = date?.getSeconds();
    let ampm = hh >= 12 ? "pm" : "am";
    hh = hh % 12;
    hh = hh ? hh : 12; // the hour '0' should be '12'
    min = min < 10 ? "0" + min : min;
    sec = sec < 10 ? "0" + sec : sec;
    let strTime = hh + ":" + min + ":" + sec + " " + ampm;
    date = mm + "/" + dd + "/" + yyyy;
    const dateTime = date + "  " + "  " + strTime;
    return dateTime;
  }

  return (
    <>
      <div className={classes.container}>
        <Link to={`/blogs/${item.docId}`}>
          <Card className={classes.root}>
            <div className={classes.media}>
              <img src={item?.blogImage} alt="" className={classes.blogImg} />
            </div>

            <div className={classes.content}>
              <div className={classes.chips}>
                {item?.blogKeywords?.map((item, index) => {
                  return (
                    <Chip
                      label={item}
                      variant="outlined"
                      className={classes.chip}
                    />
                  );
                })}
              </div>

              <Typography variant="h5" className={classes.Header}>
                {item?.blogTitle}
              </Typography>

              <Typography className={classes.readingTime}>
                5 min read
              </Typography>

              <div
                ref={blogRef}
                style={{ fontWeight: 400, fontSize: 13, padding: 0, margin: 0 }}
              ></div>
              <Typography
                style={{ fontSize: 12, color: "#5C36BB", fontWeight: 400 }}
              >
                Read more...
              </Typography>
            </div>
            <Divider />

            <div className={classes.footer}>
              <div className={classes.leftFooter}>
                <Avatar src={item?.blogAuthorImage} alt={item?.blogAuthor} />
                <Typography>{item?.blogAuthor}</Typography>
              </div>

              <div>
                <Typography className={classes.dateTime}>
                  {convertTimestamp(item?.updatedDate)}
                </Typography>
              </div>
            </div>
          </Card>
        </Link>
      </div>
    </>
  );
};

export default BlogsBox;
