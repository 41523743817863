import { ProductCard } from "./ProductCard";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { getProducts } from "../../../backend/shopTab";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "35px 140px",
  },
}));

export const ProductList = () => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    handleProducts();
  }, []);

  const handleProducts = async () => {
    const res = await getProducts();
    if (res.successful) {
      setProducts(res.data);
    }
  };

  return (
    <div className={`${classes.root} max-sm:p-5`}>
      <Grid container spacing={5}>
        {products?.length ? (
          products?.map((product, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={2}>
                <ProductCard products={product} />
              </Grid>
            );
          })
        ) : (
          <Typography variant="h4">Not found</Typography>
        )}
      </Grid>
    </div>
  );
};
