import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showsExit : false
}



const userSlice = createSlice({
    name : 'user',
    initialState,
    reducers : {
        setQuiz : (state, action) => {
            state.showsExit = action.payload.showsExit;

        }
    },
})

export const {setQuiz} = userSlice.actions;
export const selectUser = (state) => state.user;

export default userSlice.reducer;