import React, { useEffect, useState } from "react";
import { AssignmentCard } from "./AssignmentCard";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PerCourseAssignment } from "../../../backend/PerCourseData";

const useStyles = makeStyles(() => ({
  heading: {
    padding: "38px 0px",
    maxWidth: 800,
  },
}));

export const Assignments = () => {
  const classes = useStyles();

  const [assignmentData, setAssignmentData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getAssignmentCollection(id);
  }, [id]);

  const getAssignmentCollection = async (id) => {
    const result = await PerCourseAssignment(id);
    setAssignmentData(result);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
        {assignmentData?.map((assignment) => (
          <AssignmentCard item={assignment} />
        ))}
      </div>
    </React.Fragment>
  );
};
