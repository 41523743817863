import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EventsHeader } from "./EventsHeader";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Typography } from "@material-ui/core";
import { getEvents } from "../../backend/getData";
import { ViewEvents } from "./ViewEvents";
import LoginNavbar from "../Home/LoginNavbar";
import AnimationWrapper from "../../common/AnimationWrapper";

const useStyles = makeStyles(() => ({
  container: {
    background: "#eee",
    padding: "20px 30px",
    minHeight: 1024,
  },
  tabs: {
    width: 650,
    "& .MuiPaper-root": {
      background: "#eee",
      borderBottom: "1px solid #CCCCCC",
      width: 625,
      boxShadow: "none",
    },
  },
  eventCards: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "0px 30px",
  },
}));

const RenderComponent = ({ renderComponent, events }) => {
  switch (renderComponent) {
    case 0:
      return <ViewEvents events={events} />;
    case 1:
      return <ViewEvents events={events} />;
    case 2:
      return <ViewEvents events={events} />;
    case 3:
      return <ViewEvents events={events} />;
    default:
      return <ViewEvents events={events} />;
  }
};

const FILTERS = {
  0: "all",
  1: "workshop",
  2: "bootcamp",
  3: "webinar",
};

export const Events = () => {
  const classes = useStyles();
  const [events, setEvents] = React.useState([]);
  const [filteredEvents, setFilteredEvents] = React.useState([]);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    handleGetData();
  }, []);

  useEffect(() => {
    if (value !== 0) {
      const result = events.filter(
        (event) => event.eventCategory === FILTERS[value]
      );
      setFilteredEvents(result);
    } else {
      setFilteredEvents(events);
    }
  }, [value, events]);

  const handleGetData = async () => {
    const res = await getEvents();
    if (res?.successful) {
      setEvents(res?.data);
      setFilteredEvents(res?.data);
    } else {
      setEvents([]);
      setFilteredEvents([]);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <AnimationWrapper title="Event">
        <LoginNavbar />
        <div>
          <EventsHeader />
          <div className={classes.container}>
            <div className={classes.tabs}>
              <Paper square>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                >
                  <Tab
                    label={<Typography>ALL EVENT</Typography>}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    textColor="primary"
                  />
                  <Tab
                    label={<Typography>Workshop</Typography>}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    textColor="primary"
                  />
                  <Tab
                    label={<Typography>Bootcamp</Typography>}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    textColor="primary"
                  />
                  <Tab
                    label={<Typography>Webinars</Typography>}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    textColor="primary"
                  />
                </Tabs>
              </Paper>
            </div>
            <RenderComponent renderComponent={value} events={filteredEvents} />
          </div>
        </div>
      </AnimationWrapper>
    </>
  );
};
