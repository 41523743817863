import React, { useState } from "react";
import styled from "styled-components";
//pre
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Solution = (props) => {
  const { view, quizData, currentQuestion } = props;
  const [show, setShow] = useState(false);

  return (
    <>
      <Container show={show} style={{ display: view ? "flex" : "none" }}>
        <Btns show={show}>
          <button show={show} onClick={() => setShow(!show)}>
            Show Explanation
            <ExpandMoreIcon />
          </button>
          <Content show={show}>
            <p>
              {`Correct answer is ${quizData.questions[currentQuestion]?.["answer"]}, because ${quizData.questions[currentQuestion]?.["description"]}`}
            </p>
          </Content>
        </Btns>
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-top: 48px;
  width: 100%;
  height: 100px;
  padding: 10px;
  position: relative;
  /* background-color: #fff; */
  /* top: ${(props) => (props.show ? "-110px" : "-30px")}; */
  transition: all 0.3s ease;
  /* background-color: red; */
  /* background-color: transparent; */
`;

const Btns = styled.div`
  text-align: center;
  /* background-color: green; */
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  /* top: ${(props) => (props.show ? "-110px" : "-30px")}; */
  /* gap: 10px; */
  /* align-content: center; */
  button {
    position: relative;
    top: ${(props) => (props.show ? "-110px" : "-80px")};
    padding: 10px 15px;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    /* border-bottom: none; */
    /* border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
    cursor: pointer;
    background-color: #fff;
  }
`;

const Content = styled.div`
  position: relative;
  top: ${(props) => (props.show ? "-130px" : "-20px")};
  border: 1px solid black;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  text-align: left;
  margin-top: 30px;
  /* padding-bottom: 20px; */
  margin-bottom: 10px;

  p {
    background-color: #fff;
  }
`;

export default Solution;
