import "./App.css";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import { ProductProvider } from "./components/Context/ProductContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppRouter } from "./Router/Router";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ProductProvider>
        <AppRouter />
      </ProductProvider>
      <ToastContainer position="top-center" />
    </ThemeProvider>
  );
}

export default App;
