import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    time : '',
    second : 0
}



const timerSlice = createSlice({
    name : 'timer',
    initialState,
    reducers : {
        setTime : (state, action) => {
            state.time = action.payload.time;
            state.second = action.payload.second;
        }
    },
})

export const {setTime} = timerSlice.actions;
export const selectTimer = (state) => state.timer.second;

export default timerSlice.reducer;