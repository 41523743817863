import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CheckoutModal from "./CheckoutModal";

const useStyles = makeStyles({
  root: {
    height: 251,
    width: 333,
    boxShadow: "0px 0px 9px 3px #00000026",
    padding: "26px 32px 25px 26px",
    "& .MuiTypography-root": {
      fontWeight: "700",
    },
  },
  header: {
    color: "#140045",
  },
  divider: {
    marginBottom: 15,
    marginTop: 15,
  },
  totalContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 42,
  },
});

export const CartSubTotal = ({ products }) => {
  const classes = useStyles();
  const [subTotal, setSubTotal] = useState(0);

  useEffect(() => {
    const total = products
      ?.map((item) => parseFloat(item["discountPrice"]) * item?.quantity)
      ?.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
    setSubTotal(parseFloat(total).toFixed(2));
  }, [products]);

  return (
    !!parseFloat(subTotal) && (
      <Card className={classes.root}>
        <Typography variant="h6" className={classes.header}>
          Subtotal (2 items)
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.totalContainer}>
          <Typography>Total</Typography>
          <Typography>₹{subTotal}</Typography>
        </div>
        <div className={classes.buttonContainer}>
          <CheckoutModal cartProducts={products} />
        </div>
      </Card>
    )
  );
};
