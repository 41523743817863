import React from "react";
import AnimationWrapper from "../../common/AnimationWrapper";
import WebDevHero from "./WebDevHero";
import WebDevPricing from "./WebDevPricing";
import WebDevPromo from "./WebDevPromo";
import WebDevLastSection from "./WebDevLastSection";
import WebDevSkills from "./WebDevSkills";

const WebDev = () => {
  return (
    <AnimationWrapper title="Website Development">
      <WebDevHero />
      <WebDevPricing />
      <WebDevSkills />
      <WebDevPromo />
      <WebDevLastSection />
    </AnimationWrapper>
  );
};

export default WebDev;
