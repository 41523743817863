import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { INPUT_IMAGE_PATH } from "../../../common/const";
import { StartQuizButton } from "../../../common/Button";
import { useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    boxShadow: "3.11321px 3.11321px 31.1321px rgba(0, 0, 0, 0.06)",
  },
  container: {
    height: "237px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    width: 392,
    borderRadius: 15,
    overflow: "hidden",
  },
  desc: {
    height: "100px",
    margin: "30px 15px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #D9D9D9",
    padding: "10px 15px",
    alignItems: "center",
  },
}));

export const QuizCard = ({ item }) => {
  const { id: courseId } = useParams();

  const navigate = useNavigate();
  const { description, questions, title, duration, quizId, id } = item;
  const classes = useStyles();

  const handleStartQuiz = () => {
    navigate(`/course/${courseId}/quiz/${id}/${quizId}`);
  };

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <div className={classes.desc}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontWeight: 600, color: "#474747" }}
                gutterBottom
              >
                {title}
              </Typography>
              {item?.completed && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <img src={INPUT_IMAGE_PATH.QUIZ_RIGHT} alt="" />
                  <Typography>completed</Typography>
                </div>
              )}
            </div>
            <Typography>{description}</Typography>
          </div>

          <div className={classes.footer}>
            <div style={{ textAlign: "center" }}>
              <Typography style={{ fontWeight: 600 }}>Time</Typography>
              <Typography>{duration} min</Typography>
            </div>
            <div>
              <Typography style={{ fontWeight: 600 }}>Questions</Typography>
              <Typography>{questions}</Typography>
            </div>
            <div>
              {item?.action ? (
                <StartQuizButton
                  startIcon={<PlayCircleFilledIcon />}
                  variant="contained"
                  color="#5C36BB"
                  style={{ background: "#5C36BB" }}
                >
                  Retake
                </StartQuizButton>
              ) : (
                <StartQuizButton
                  onClick={handleStartQuiz}
                  startIcon={<PlayCircleFilledIcon />}
                  variant="contained"
                  color="primary"
                >
                  Start
                </StartQuizButton>
              )}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
