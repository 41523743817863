import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    boxShadow: "3.11321px 3.11321px 31.1321px rgba(0, 0, 0, 0.06)",
  },

  button: {
    backgroundColor: "#18A136",
    width: "101px",
    fontWeight: 500,
    color: "#fff",
    height: 46,

    "&:hover": {
      backgroundColor: "#18A136",
    },
  },

  container: {
    height: "230px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    width: 392,
    borderRadius: 15,
    overflow: "hidden",
  },

  desc: {
    height: "100px",
    margin: "30px 15px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #D9D9D9",
    padding: "10px 15px",
    alignItems: "center",
  },
}));

const CardAssignment = ({ item }) => {
  const { title, description, duration, questions, assignmentId, type } = item;

  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <div className={classes.desc}>
            <Typography variant="h6" style={{ fontWeight: 600 }} gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2" component="p">
              {description}
            </Typography>
          </div>

          <div className={classes.footer}>
            <div>
              <Typography style={{ fontWeight: 600 }}>Time</Typography>
              <Typography>{duration / 60} min</Typography>
            </div>
            <div>
              <Typography style={{ fontWeight: 600 }}>Questions</Typography>
              <Typography>{questions}</Typography>
            </div>
            <div>
              <Button
                className={classes.button}
                variant="contained"
                startIcon={<PlayCircleFilledIcon />}
              >
                Start
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default CardAssignment;
