import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Navbar from "../../components/Navbar";
import QuizHeader from "./QuizHeader";
import QuizCards from "./QuizCards";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { interviewData, INPUT_IMAGE_PATH } from "../../common/const";
import InterviewCards from "./InterviewCards";
import { fetchAllQuiz } from "../../backend/fetchQuizData";

const useStyles = makeStyles(() => ({
  quizBody: {
    padding: "0 25px",
    paddingBottom: 30,
  },
  quizHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  right: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    color: "#5C36BB",
    fontWeight: 600,
  },
  quizRow: {
    display: "flex",
    flexWrap: "wrap",
    gap: 20,
    marginTop: 30,
  },
  bars: {
    position: "relative",
    width: "60px",
    height: "60px",
    top: "10px",
    left: "-20px",
  },
  arrow: {
    position: "relative",
    width: "50px",
    height: "50px",
    bottom: "-10%",
  },
}));

const Quiz = () => {
  const [courseData, setCourse] = useState([]);

  useEffect(() => {
    getQuizes();
  }, []);

  const getQuizes = async () => {
    const studentDocId = localStorage.getItem("docId");
    if (studentDocId) {
      const dataRef = await fetchAllQuiz(studentDocId);
      setCourse(dataRef);
    }
  };

  const classes = useStyles();

  return (
    <>
      <Container banner={INPUT_IMAGE_PATH.QUIZ_BANNER}>
        <Navbar />
        <QuizHeader />

        <div className={classes.quizBody}>
          <div className={classes.quizHeader}>
            <div style={{ position: "relative" }}>
              <div className={classes.bars}>
                <img
                  src={INPUT_IMAGE_PATH.QUIZ_BARS}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <Typography variant="h4" component="h4" style={{ color: "#333" }}>
                Programming
              </Typography>
              <p style={{ color: "#666" }}>
                Select a Programming Language and start practicing
              </p>
            </div>
            <div>
              <div className={classes.right}>
                <p>View More</p>
                <ArrowForwardIosIcon />
              </div>

              <div className={classes.arrow}>
                <img
                  src={INPUT_IMAGE_PATH.QUIZ_ARROW}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>

          <div className={classes.quizRow}>
            {courseData?.map((item, index) => (
              <QuizCards key={index} item={item} />
            ))}
          </div>
        </div>

        <div className={classes.quizBody}>
          <div className={classes.quizHeader}>
            <div>
              <div className={classes.bars}>
                <img
                  src={INPUT_IMAGE_PATH.QUIZ_BARS}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <Typography
                variant="h4"
                style={{ color: "#333", fontWeight: 500, fontSize: "30px" }}
              >
                Get Ready for Interview
              </Typography>
              <p style={{ color: "#666" }}>
                A curated list of topic wise quizzes
              </p>
            </div>

            <div>
              <div className={classes.right}>
                <p>View More</p>
                <ArrowForwardIosIcon />
              </div>

              <div className={classes.arrow}>
                <img
                  src={INPUT_IMAGE_PATH.QUIZ_ARROW}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>

          <div className={classes.quizRow}>
            {interviewData.map((item, index) => (
              <InterviewCards key={index} item={item} />
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  background: #eeeeee;
  /* min-height: 100vh; */
  background-image: url(${(props) => props.banner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export default Quiz;
