import React, { useState } from "react";
import styled from "styled-components";
import Endquiz from "./Endquiz";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const LeftPanel = ({
  quizData,
  currentQue,
  submitted,
  showView,
  setCurrentQue,
  setChangeQue,
  viewSoln,
}) => {
  const [model, setModel] = useState("close");

  const handlerExit = () => {
    switch (model) {
      case "close":
        setModel("open");
        break;
      case "open":
        setModel("close");
        break;
      default:
        setModel("close");
    }
  };

  const setQue = (id) => {
    setChangeQue(id);
  };

  return (
    <Container>
      <Header>
        <ArrowBackIosIcon />
        <h5 onClick={handlerExit}>BACK</h5>
      </Header>

      <Totalque>
        <h3>Total Questions</h3>
        <QuesData currentQue={currentQue}>
          {quizData?.questions?.map((item, id) => {
            return (
              <Ques
                key={id}
                style={{
                  background:
                    currentQue === id + 1
                      ? "#F2B318"
                      : Boolean(submitted[id]) || false
                      ? "#4ED338"
                      : "",
                }}
                className={
                  showView[id] === item.answer
                    ? "bgGreen"
                    : showView[id]
                    ? "bgRed"
                    : ""
                }
                currentQue={currentQue}
                onClick={() => setQue(id)}
              >
                <h3>{id + 1}</h3>
              </Ques>
            );
          })}
        </QuesData>
      </Totalque>
      <Indicator>
        <Sign>
          <Ball style={{ background: "#4ED338" }} />
          <p>Submitted</p>
        </Sign>

        <Sign>
          <Ball
            style={{ background: "#F2B318" }}
            className={Object.keys(showView)?.length === 0 ? "" : "circleRed"}
          />
          <p>
            {Object.keys(showView)?.length === 0
              ? " Ongoing"
              : " Wrong Answers"}
          </p>
        </Sign>

        <Sign>
          <Ball style={{ background: "#9D86D6" }} />
          <p>Not completed</p>
        </Sign>
      </Indicator>

      <Endquiz
        handlerExit={handlerExit}
        model={model}
        showView={showView}
        viewSoln={viewSoln}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 20%;
  max-height: 100vh;
  background-color: #5c36bb;
  padding: 20px;
`;

const Header = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  h5 {
    font-size: 15px;
    line-height: 1;
    cursor: pointer;
  }
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;

const Totalque = styled.div`
  margin-top: 25px;
  color: #fff;
  position: relative;
  h3 {
    font-size: 20px;
    font-weight: 600;
    z-index: 999;
  }
`;

const QuesData = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 20px;
  row-gap: 15px;
  flex-wrap: wrap;
  padding-top: 10px;
  align-items: flex-start;
  max-height: 63vh;
  overflow: hidden;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Ques = styled.div`
  background: rgba(255, 255, 255, 0.4);
  color: #fff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: ${(props) =>
    props.currentQue > 32 ? "translateY(-300px)" : "translateY(0px)"};
  h3 {
    font-size: 20px;
    font-weight: 700;
  }
`;

const Indicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 40px 0;
  gap: 10px;
`;

const Sign = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  p {
    font-size: 14px;
    font-weight: 400;
  }
`;

const Ball = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

export default LeftPanel;
