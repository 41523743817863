import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { StartQuizButton } from "../../../common/Button";
import { formatDate } from "../../../common/const";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    boxShadow: "3.11321px 3.11321px 31.1321px rgba(0, 0, 0, 0.06)",
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 15px",
  },
  container: {
    height: "137px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    width: 392,
    borderRadius: 15,
    overflow: "hidden",
  },
  desc: {
    height: "100px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #D9D9D9",
    padding: "10px 15px",
    alignItems: "center",
  },
}));

export const VideoCard = ({ item }) => {
  const { title, link, updatedDate } = item;
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <div className={classes.desc}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontWeight: 600, color: "#474747" }}
                gutterBottom
              >
                {title}
              </Typography>
            </div>
            <Typography>Updated Date: {formatDate(updatedDate)}</Typography>
          </div>
          <div>
            <StartQuizButton
              onClick={() => window.open(link, "_blank", "noreferrer")}
              variant="contained"
              color="primary"
            >
              Link
            </StartQuizButton>
          </div>
        </Card>
      </div>
    </>
  );
};
