import React from "react";
import { AskHeader } from "./AskHeader";
import Navbar from "../../components/Navbar";

export const Ask = () => {
  return (
    <React.Fragment>
      <Navbar />
      <AskHeader />
    </React.Fragment>
  );
};
