import React from "react";
import styled from "styled-components";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useDispatch } from "react-redux";
import { setTime } from "../features/timerSlice";
import { useNavigate } from "react-router-dom";

const Box = (props) => {
  const { desc, time, bgImg, quiz, img, link, title, second } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setTimer = async (id) => {
    dispatch(
      setTime({
        time: time,
        second: second,
      })
    );
    navigate(`/questions/${id}`);
  };

  return (
    <>
      <BoxContainer>
        <BgImg>
          <img src={bgImg} alt="" />
        </BgImg>
        <QuizLogo>
          <img src={img} alt="" />
        </QuizLogo>

        <Description>
          <h3>{title}</h3>
          <p>{desc}</p>
        </Description>

        <Timers>
          <ul>
            <li>
              <h4>Time</h4>
              <span>{time}</span>
            </li>
            <li>
              <h4>Quizzes</h4>
              <span>{quiz}</span>
            </li>
            <li>
              <button onClick={() => setTimer(link)}>
                <PlayCircleFilledIcon />
                <h5>Start</h5>
              </button>
            </li>
          </ul>
        </Timers>
      </BoxContainer>
    </>
  );
};

const BoxContainer = styled.div`
  width: 100%;
  min-width: 400px;
  max-width: 450px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  color: #000;
  box-shadow: 3.10377px 3.10377px 31.0377px rgba(0, 0, 0, 0.06);
  position: relative;
  flex-basis: 100%;
  transition: all 0.3s ease-in-out;
  scroll-behavior: smooth;
`;

const BgImg = styled.div`
  position: relative;
  width: 100%;
  height: 150px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
  }
`;

const QuizLogo = styled.div`
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-top: -30px;
  margin-left: 10px;
  z-index: 999;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
    border-radius: 50%;
  }
`;

const Description = styled.div`
  margin: 20px 0;
  padding: 10px 13px;
`;

const Timers = styled.div`
  border: 1.55189px solid #e6e6e6;
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 10px;
    justify-content: space-between;
    padding: 10px 13px;
  }

  button {
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    background-color: #23c446;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 5px;

    h5 {
      font-size: 13px;
    }
  }
`;

export default Box;
