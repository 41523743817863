import { createContext, useState, useEffect } from "react";
import { cartDataStorageKey } from "../../common/configKeys";
import { toast } from "react-toastify";

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    const data = localStorage.getItem(cartDataStorageKey);
    data && setCartData(JSON.parse(data));
  }, []);

  useEffect(() => {
    cartData.length
      ? localStorage.setItem(cartDataStorageKey, JSON.stringify(cartData))
      : localStorage.removeItem(cartDataStorageKey);
  }, [cartData]);

  const addToCartClick = (id, qty, name) => {
    setCartData((prev) => {
      const isPresentAlready = prev.findIndex((item) => item.id === id);
      if (isPresentAlready >= 0) {
        const data = { ...prev[isPresentAlready] };
        data.qty = data.qty + qty;
        prev[isPresentAlready] = data;
        return [...prev];
      }
      return [...prev, { id, qty }];
    });
    toast.success(`${qty} ${name} has been added to cart`);
  };

  const resetCart = () => {
    setCartData([]);
  };

  const deleteItemFromCart = (id) => {
    setCartData((prev) => {
      const isPresentAlready = prev.findIndex((item) => item.id === id);

      if (isPresentAlready > -1) {
        prev.splice(isPresentAlready, 1);
        alert("Success! tem has been removed from cart success");
        return [...prev];
      }
      return [...prev];
    });
  };

  const updateQty = (id, qty) => {
    setCartData((prev) => {
      const isPresentAlready = prev.findIndex((item) => item.id === id);
      if (isPresentAlready >= 0) {
        const data = { ...prev[isPresentAlready] };
        data.qty = qty;
        prev[isPresentAlready] = data;
        return [...prev];
      }
      return [...prev, { id, qty }];
    });
  };

  const getQtyInCart = () => {
    let qty = 0;
    if (cartData.length) {
      cartData.forEach((item) => {
        qty = qty + item.qty;
      });
      return qty;
    }
    return qty;
  };

  const renewCartData = async (data) => {
    setCartData(data);
  };

  return (
    <>
      <ProductContext.Provider
        value={{
          addToCartClick,
          resetCart,
          cartData,
          cartDataItem: getQtyInCart(),
          deleteItemFromCart,
          updateQty,
          renewCartData,
        }}
      >
        {children}
      </ProductContext.Provider>
    </>
  );
};

export default ProductContext;
