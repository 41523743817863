import React from "react";
import { ProductFilter } from "./ProductsFIlter";
import { ProductList } from "./ProductList";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Footer } from "../../Home/Footer";
import { SimilarProducts } from "../../../components/SuggestProducts/SimilarProducts";
import AnimationWrapper from "../../../common/AnimationWrapper";
import LoginNavbar from "../../Home/LoginNavbar";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "600",
    fontSize: 32,
    paddingLeft: 140,
    paddingTop: 29,
  },
}));

export const Products = () => {
  const classes = useStyles();

  return (
    <AnimationWrapper title="Product">
      <React.Fragment>
        <LoginNavbar />
        <ProductFilter />
        <Typography
          className={`${classes.heading} max-sm:mt-7 max-sm:text-center max-sm:p-0`}
          variant="h4"
        >
          All Products
        </Typography>
        <ProductList />
        <Footer />
      </React.Fragment>
    </AnimationWrapper>
  );
};
