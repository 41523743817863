import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 20,
  },
  heading: {
    fontSize: 21,
    fontWeight: "600",
    padding: 10,
  },
  subHeading: {
    fontWeight: "500",
  },
  list: {
    fontSize: 15,
    padding: 10,
  },
}));

export const SyllabusCard = ({ data }) => {
  const classes = useStyles();
  const syllabusRef = useRef();

  const { title, syllabus } = data;

  useEffect(() => {
    if (syllabusRef.current) {
      syllabusRef.current.innerHTML = syllabus;
    }
  }, [syllabus]);

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px 10px",
            }}
          >
            <div>
              <Typography ref={syllabusRef} className={classes.subHeading} />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
