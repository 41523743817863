import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  progressBar: {
    backgroundColor: "#D9D9D9",
    height: "6px",
    borderRadius: 5,

    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#23C346",
    },
  },

  progreCount: {
    color: "#23C346",
    fontWeight: 400,
    fontSize: "12px",
    textAlign: "right",
    padding: "5px 0px",
  },
  button: {
    backgroundColor: "#18A136",
    width: "100%",
    fontWeight: 500,
    color: "#fff",

    "&:hover": {
      backgroundColor: "#18A136",
    },
  },

  container: {
    height: "400px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    width: 350,
    borderRadius: 15,
    overflow: "hidden",
  },
  Logo: {
    position: "relative",
    top: "-30px",
    left: "10px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
    borderRadius: "50%",
    width: "77.59px",
    height: "77.59px",
  },
  desc: {
    margin: "0px 0px",
    height: "80px",
    padding: "0px 10px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #D9D9D9",
    padding: "10px 15px",
  },
}));

const InterviewCards = ({ item }) => {
  const { banner, time, questions, title, description, image } = item;
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <div style={{ width: "100%" }}>
            <img
              src={banner}
              alt=""
              style={{ objectFit: "cover", width: "100%" }}
            />
          </div>

          <div className={classes.Logo}>
            <img src={image} alt="" />
          </div>

          <div className={classes.desc}>
            <Typography variant="h6" style={{ fontWeight: 600 }} gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2" component="p">
              {description}
            </Typography>
          </div>

          <div className={classes.footer}>
            <div>
              <p>Time</p>
              <p>{time} hr</p>
            </div>
            <div>
              <p>Questions</p>
              <p>{questions}</p>
            </div>
            <div>
              <Button
                className={classes.button}
                variant="contained"
                startIcon={<PlayCircleFilledIcon />}
              >
                Start
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default InterviewCards;
