import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { StartQuizButton } from "../../../common/Button";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    boxShadow: "3.11321px 3.11321px 31.1321px rgba(0, 0, 0, 0.06)",
  },
  container: {
    height: "237px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    width: 392,
    borderRadius: 15,
    overflow: "hidden",
  },
  desc: {
    height: "100px",
    margin: "30px 15px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #D9D9D9",
    padding: "10px 15px",
    alignItems: "center",
  },
}));

export const AssignmentCard = ({ item }) => {
  const { description, title, marks, submissionDate, file } = item;
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <div className={classes.desc}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontWeight: 600, color: "#474747" }}
                gutterBottom
              >
                {title}
              </Typography>
            </div>
            <Typography>{description}</Typography>
          </div>

          <div className={classes.footer}>
            <div style={{ textAlign: "center" }}>
              <Typography style={{ fontWeight: 600 }}>Marks</Typography>
              <Typography>{marks}</Typography>
            </div>
            <div>
              <Typography style={{ fontWeight: 600 }}>Submission</Typography>
              <Typography>{submissionDate}</Typography>
            </div>
            <div>
              <StartQuizButton
                startIcon={<PlayCircleFilledIcon />}
                variant="contained"
                color="primary"
                onClick={() => window.open(file, "_blank")}
              >
                Open
              </StartQuizButton>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
