import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Avatar, IconButton } from "@material-ui/core";
import { logOutUser } from "../backend/authenticateUser";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 10,

    color: "#f2b318",
    fontSize: 15,
  },
}));

const UserAvatar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userName = localStorage.getItem("name");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "avatar-popover" : undefined;

  return (
    <div>
      <IconButton className={classes.margin} onClick={handleClick}>
        <Avatar alt={userName} src="/static/images/avatar/1.jpg" />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.container}>
          <Typography>Welcome, {userName}</Typography>
          <Link to="/edit-bio">Edit Bio</Link>
          <button onClick={logOutUser}>Logout</button>
        </div>
      </Popover>
    </div>
  );
};

export default UserAvatar;
