import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LeftPanel from "./Leftpanel";
import RightPanel from "./Rightpanel";
import Navbar from "./Navbar";

const Home = ({ courseQuiz, time }) => {
  const [currentQue, setCurrentQue] = useState(1);
  const [submitted, setSubmitted] = useState({});
  const [showView, setShowView] = useState({});
  const [changque, setChangeQue] = useState(0);
  const [viewSoln, setViewSoln] = useState(false);
  const [showTime, setTime] = useState("");
  const [quizData, setQuiz] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchQuiz();
  }, [courseQuiz]);

  const fetchQuiz = async () => {
    setQuiz(courseQuiz);
    setLoading(false);
    setTime(time);
  };

  return (
    <>
      {!loading ? (
        <Main>
          <Navbar />
          <Container>
            <LeftPanel
              quizData={quizData}
              currentQue={currentQue}
              submitted={submitted}
              showView={showView}
              setCurrentQue={setCurrentQue}
              setChangeQue={setChangeQue}
              viewSoln={viewSoln}
            />

            <RightPanel
              quizData={quizData}
              expiryTimestamp={showTime}
              setCurrentQue={setCurrentQue}
              setSubmitted={setSubmitted}
              setShowView={setShowView}
              currentQue={currentQue}
              changque={changque}
              setViewSoln={setViewSoln}
              submitted={submitted}
            />
          </Container>
        </Main>
      ) : (
        <h1>loading</h1>
      )}
    </>
  );
};

const Main = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export default Home;
