import { doc, getDoc } from "firebase/firestore";
import db from "../../firebase";

export const getUserDetails = async (userId) => {
  try {
    const docRef = doc(db, "Students", userId);
    const querySnap = await getDoc(docRef);
    if (!querySnap.exists()) {
      return { successful: false, message: "Quiz not exist" };
    }
    const data = querySnap.data();
    return { successful: true, data };
  } catch (error) {
    console.log(error);
    return { successful: false, message: error };
  }
};
