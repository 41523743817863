import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import SearchIcon from '@mui/icons-material/Search';
import SearchIcon from "@material-ui/icons/Search";
import bgImg from "../assets/code.jpg";
import BlogsBox from "../components/BlogsBox";
// import BlogsData from '../data/Blogs';
import { getBlogs } from "../backend/getData";
import LoginNavbar from "./Home/LoginNavbar";
import AnimationWrapper from "../common/AnimationWrapper";

const Navs = [
  {
    id: 1,
    title: "ALL",
  },
  {
    id: 2,
    title: "POPULAR",
  },
  {
    id: 3,
    title: "RECENT",
  },
];

const Blogs = () => {
  const [blogsData, setBlogData] = useState([]);
  const [pages, setPages] = useState("ALL");
  const [searchBlog, setBlogSearch] = useState("");

  useEffect(() => {
    getBlogsList();
  }, []);
  const getBlogsList = async () => {
    try {
      const blogsRef = await getBlogs();
      setBlogData(blogsRef);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(blogsData)

  const handlerSearch = (e) => {
    setBlogSearch(e.target.value);
  };

  return (
    <>
      <AnimationWrapper title="Blog">
        <LoginNavbar />
        <Container>
          <Header>
            <h4>Blogs</h4>
            <h6>Amazing collection of coding blogs available below.</h6>
            <SearchBar>
              <SearchIcon />
              <input
                type="text"
                placeholder="Search Amazing Blogs...."
                value={searchBlog}
                onChange={handlerSearch}
              />
            </SearchBar>
          </Header>
        </Container>

        <Main>
          <NaviagtionBar>
            {Navs.map((item) => {
              return (
                <NavBtn
                  onClick={() => setPages(item.title)}
                  style={{
                    borderBottom:
                      item.title === pages
                        ? "2px solid #5C36BB"
                        : "2px solid transparent",
                    color: item.title === pages ? "#5C36BB" : "",
                  }}
                >
                  {item.title}
                </NavBtn>
              );
            })}
          </NaviagtionBar>

          <BlogsMap>
            {blogsData
              .filter((item) =>
                searchBlog === "" || searchBlog === null
                  ? item
                  : item.title.toLowerCase().includes(searchBlog.toLowerCase())
              )
              .map((item, index) => {
                return <BlogsBox item={item} key={index} />;
              })}
          </BlogsMap>
        </Main>
      </AnimationWrapper>
    </>
  );
};

const Container = styled.div`
  background: #5c36bb;
  height: 250px;
  position: relative;
`;

const Header = styled.div`
  color: #fff;
  text-align: center;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  h4 {
    font-size: 34px;
    font-weight: 700;
    line-height: 123.5%;
    text-transform: uppercase;
    letter-spacing: 0.25px;
  }
  h6 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.15px;
  }
`;

const SearchBar = styled.div`
  height: 45px;
  width: 100%;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin: 15px auto;
  display: flex;
  align-items: center;
  padding: 0 15px;

  input {
    height: 100%;
    flex-grow: 1;
    border: none;
    background: transparent;
    outline: none;
    color: #fff;
    margin-left: 10px;
    &::placeholder {
      color: #fff;
    }
  }
`;

const NaviagtionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: fit-content;
`;

const NavBtn = styled.button`
  color: #5c36bb;
  background-color: transparent;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  padding: 10px;
  border-bottom: 2px solid;
  font-weight: 600;
  cursor: pointer;
  color: #808080;
`;

const Main = styled.div`
  padding: 10px 20px;
  background: #eeeeee;
`;

const BlogsMap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
`;
export default Blogs;
