import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PreviewCards from "../PreviewCards";
import { PerCourseQuiz } from "../../../backend/PerCourseData";
import db from "../../../firebase";
import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  main: {
    padding: "15px 25px",
  },
  quizBody: {
    marginTop: 20,
  },

  quizRow: {
    display: "flex",
    flexWrap: "wrap",
    gap: 20,
  },
}));

const card = [
  {
    id: 1,
    title: "Quiz 1",
    desc: "This quiz is a mixture of all the HTML Topics",
    time: "1",
    totalQues: "50",
    action: false,
  },
  {
    id: 2,
    title: "Quiz 2",
    desc: "This quiz is a mixture of all the HTML Topics",
    time: "1",
    totalQues: "50",
    action: true,
    completed: true,
  },
];

const QuizeSection = () => {
  const [tableData, setTableQuiz] = useState([]);
  const [formData, setFormQuiz] = useState([]);
  const location = useLocation();
  useEffect(() => {
    getQuizCollection();
  }, []);
  const getQuizCollection = async () => {
    const locationID = location.pathname.split("/")[2];
    const result = await PerCourseQuiz(locationID);
    result.map((item) => {
      if (item.type === "Table") {
        setTableQuiz([...tableData, item]);
      }
      if (item.type === "Form Handling") {
        setFormQuiz([...formData, item]);
      }
    });
  };

  const classes = useStyles();
  return (
    <>
      <div className={classes.quizBody}>
        <Typography
          variant="h6"
          style={{ fontWeight: 600, fontSize: "15px" }}
          gutterBottom
        >
          TABLES QUIZZES
        </Typography>
        <div className={classes.quizRow}>
          {tableData?.map((item, index) => (
            <PreviewCards item={item} key={index} />
          ))}
        </div>
      </div>

      <div className={classes.quizBody}>
        <Typography
          variant="h6"
          style={{ fontWeight: 600, fontSize: "15px" }}
          gutterBottom
        >
          FORM HANDLING QUIZZES
        </Typography>

        <div className={classes.quizRow}>
          {formData?.map((item, index) => (
            <PreviewCards item={item} key={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default QuizeSection;
