import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { StartQuizButton } from "../../common/Button";
import { INPUT_IMAGE_PATH } from "../../common/const";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    boxShadow: "3.11321px 3.11321px 31.1321px rgba(0, 0, 0, 0.06)",
  },

  button: {
    backgroundColor: "#18A136",
    width: "100%",
    fontWeight: 500,
    color: "#fff",

    "&:hover": {
      backgroundColor: "#18A136",
    },
  },

  container: {
    height: "230px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    width: 392,
    borderRadius: 15,
    overflow: "hidden",
  },

  desc: {
    height: "100px",
    margin: "30px 15px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #D9D9D9",
    padding: "10px 15px",
    alignItems: "center",
  },
}));

const PreviewCards = ({ item }) => {
  const { description, id, questions, quizId, title, type, duration } = item;
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const handleStartQuiz = () => {
    const quizURL = `${location.pathname}/quiz/${id}/${quizId}`;
    navigate(quizURL);
  };

  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <div className={classes.desc}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                style={{ fontWeight: 600, color: "#474747" }}
                gutterBottom
              >
                {title}
              </Typography>
              {item?.completed && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <img src={INPUT_IMAGE_PATH.QUIZ_RIGHT} alt="" />
                  <Typography>completed</Typography>
                </div>
              )}
            </div>
            <Typography>{description}</Typography>
          </div>

          <div className={classes.footer}>
            <div style={{ textAlign: "center" }}>
              <Typography style={{ fontWeight: 600 }}>Time</Typography>
              <Typography>{duration / 60} min</Typography>
            </div>
            <div>
              <Typography style={{ fontWeight: 600 }}>Questions</Typography>
              <Typography>{questions}</Typography>
            </div>
            <div>
              {item?.action ? (
                <StartQuizButton
                  startIcon={<PlayCircleFilledIcon />}
                  variant="contained"
                  color="#5C36BB"
                  style={{ background: "#5C36BB" }}
                >
                  Retake
                </StartQuizButton>
              ) : (
                <StartQuizButton
                  startIcon={<PlayCircleFilledIcon />}
                  variant="contained"
                  color="primary"
                  onClick={handleStartQuiz}
                >
                  Start
                </StartQuizButton>
              )}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default PreviewCards;
