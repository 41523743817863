import React from 'react'
import styled from 'styled-components'
import LoderVideo from "../assets/rogue.mp4"
import {keyframes} from 'styled-components'


const Loader = ({isLoading}) => {
  return (
    <>
 <Container>
        <video autoPlay muted loop>
            <source src={LoderVideo} type="" />
        </video>
        </Container>

    </>
  )
}

const keys = keyframes`
0% {
    opacity: 1;
} 
100% {
    opacity: 0;

}
`



const Container = styled.div`
width: 100%;
height: 100vh;
position: fixed;
inset: 0;
z-index: 9999;
display: flex;
align-items: center;
justify-content: center;
background-color: #fff;
overflow: hidden;
/* animation: ${keys} 0.5s ease-in-out; */
video {
    width: 300px;
    height: 300px;
    object-fit: cover;
}
`

export default Loader
