import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PreviewCards from "../PreviewCards";
import CardAssignment from "./CardAssignment";
import { PerCourseAssignment } from "../../../backend/PerCourseData";

import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  main: {
    padding: "15px 25px",
  },
  quizBody: {
    marginTop: 20,
  },
}));
const AssignmenetSection = () => {
  const [tableData, setTableQuiz] = useState([]);
  const [formData, setFormQuiz] = useState([]);
  const location = useLocation();
  useEffect(() => {
    getQuizCollection();
  }, []);

  const getQuizCollection = async () => {
    const locationID = location.pathname.split("/")[2];
    const result = await PerCourseAssignment(locationID);

    result.map((item) => {
      if (item.type === "Table") {
        setTableQuiz([...tableData, item]);
      }
      if (item.type === "Form Handling") {
        setFormQuiz([...formData, item]);
      }
    });
  };

  const classes = useStyles();
  return (
    <>
      <div className={classes.quizBody}>
        <Typography
          variant="h6"
          style={{ fontWeight: 600, fontSize: "15px" }}
          gutterBottom
        >
          TABLES ASSIGNMENTS
        </Typography>
        <div>
          {tableData &&
            tableData?.map((item, index) => {
              return <CardAssignment item={item} key={index} />;
            })}
        </div>
      </div>

      <div className={classes.quizBody}>
        <Typography
          variant="h6"
          style={{ fontWeight: 600, fontSize: "15px" }}
          gutterBottom
        >
          FORM HANDLING QUIZZES
        </Typography>
        <div>
          {formData &&
            formData?.map((item, index) => {
              return <CardAssignment item={item} key={index} />;
            })}
        </div>
      </div>
    </>
  );
};

export default AssignmenetSection;
