import React from "react";

function WebDevLastSection() {
  return (
    <div className=" w-full pb-12">
      <div className=" max-w-7xl mx-auto h-full  px-4 md:px-6">
        <div class="section-title pt-8">
          <h2 className="uppercase">Website Made by us</h2>
          <p>WITH ♥ BY OUR DEVELOPERS</p>
        </div>
      </div>
    </div>
  );
}

export default WebDevLastSection;
