import React from "react";
import { CourseList } from "./CourseList";
import { CoursesHeader } from "./CoursesHeader";
import { makeStyles } from "@material-ui/core/styles";
import { Footer } from "../Home/Footer";
import Navbar from "../../components/Navbar";
import { useEffect } from "react";
import { getCourses } from "../../backend/getData";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "21px 61px 63px",
  },
}));

export const Courses = () => {
  const classes = useStyles();
  const [courses, setCourses] = useState([]);
  const docId = localStorage.getItem("docId");

  useEffect(() => {
    (async () => {
      const { successful, data } = await getCourses(docId);
      console.log(successful, data);
      if (successful) setCourses(data);
    })();
  }, [docId]);

  return (
    <React.Fragment>
      <Navbar />
      <CoursesHeader />
      <div className={classes.root}>
        <CourseList courses={courses} />
      </div>
      <Footer />
    </React.Fragment>
  );
};
