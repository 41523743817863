import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { useNavigate } from "react-router-dom";
import { QuizCardButton } from "../../common/Button";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 15,
    width: "100%",
    height: "100%",
    position: "relative",
    boxShadow: "3.11321px 3.11321px 31.1321px rgba(0, 0, 0, 0.06)",
    padding: "0px 5px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  progressBar: {
    backgroundColor: "#D9D9D9",
    height: "6px",
    borderRadius: 5,

    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#23C346",
    },
  },

  progreCount: {
    color: "#23C346",
    fontWeight: 400,
    fontSize: "12px",
    textAlign: "right",
    padding: "5px 0px",
  },

  button: {
    backgroundColor: "#F6B501",
    width: "100%",
    fontWeight: 600,
    color: "#000",
    boxShadow: "none",

    "&:hover": {
      backgroundColor: "#F6B501",
    },
  },
  container: {
    height: "400px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    width: 350,
  },
  Circle: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    width: "100px",
    height: "100px",
  },
  Logo: {
    marginTop: 30,
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Box>
      <Box minWidth={35}>
        <p className={classes.progreCount}>
          {`${Math.round(props.value)}% completed`}
        </p>
      </Box>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
          className={classes.progressBar}
        />
      </Box>
    </Box>
  );
}

const QuizCards = ({ item, setQuizName }) => {
  const {
    CourseLogo,
    courseDescription,
    CourseId,
    courseTitle,
    courseImage,
    id,
  } = item;
  const classes = useStyles();

  const [progress, setProgress] = React.useState(50);
  const navigate = useNavigate();
  const handlerNav = (searchWord) => {
    navigate(`/course/${searchWord}`);
  };

  return (
    <div className={classes.container}>
      <Card className={classes.root}>
        <CardContent>
          <img src={CourseLogo} alt="img" className={classes.Logo} />

          <div className={classes.Circle}>
            <img src={courseImage} alt="" />
          </div>
          <div style={{ margin: "25px 0", height: "85px" }}>
            <Typography variant="h6" style={{ fontWeight: 600 }} gutterBottom>
              {courseTitle}
            </Typography>
            <Typography>{courseDescription}</Typography>
          </div>

          <div className={classes.root}>
            <LinearProgressWithLabel value={progress} />
          </div>
        </CardContent>

        <CardActions>
          <QuizCardButton
            variant="contained"
            onClick={(e) => handlerNav(id)}
            textcolor="red"
          >
            Continue
          </QuizCardButton>
        </CardActions>
      </Card>
    </div>
  );
};

export default QuizCards;
