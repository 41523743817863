import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {
  authenticateUser,
  isUserLoggedIn,
  resetPassword,
} from "../backend/authenticateUser";
import { getAuth } from "firebase/auth";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";

const Login = ({ getUserDetails }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState("password");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showResetSpinner, setShowResetSpinner] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    setTimeout(() => {
      if (isUserLoggedIn()) {
        navigate("/home");
      }
    }, 1000);
  }, [navigate, auth.currentUser]);

  const handlerForm = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handlerSubmit = async (e) => {
    if (e) e.preventDefault();
    if (formData?.email === "" || formData?.password === "") {
      alert("Please enter all fields");
    } else {
      if (validateEmail(formData?.email)) {
        setShowSpinner(true);
        const res = await authenticateUser(formData?.email, formData?.password);
        if (res.success) {
          setShowSpinner(false);
        } else {
          setShowSpinner(false);
          alert("Error!", res.message, "error");
        }
      } else {
        setShowSpinner(false);
        alert("Please Enter Valid Email");
      }
    }
  };

  const handleResetPassword = async (e) => {
    try {
      e.preventDefault();
      setShowResetSpinner(true);
      const res = await resetPassword(formData.email);
      if (!res.success) return toast.error(res?.message);
      toast.success("Please check your mail to reset password");
    } finally {
      setTimeout(() => {
        setShowResetSpinner(false);
      }, 5000);
    }
  };

  return (
    <Container>
      <Left className="max-sm:hidden">
        <Circle />
        <Circle />
        <Header>
          <Rogue>
            <img src="/assets/rogue-logo.jpeg" alt="" />
          </Rogue>
          <h1>Welcome to Rogue Code</h1>
          <p>Together we develop a passion for learning...</p>
          <Logo>
            <img src="/assets/coding.gif" alt="" />
          </Logo>
        </Header>
      </Left>
      <Right className="max-sm:w-full">
        <h1>Login</h1>
        <Form>
          <form>
            <div>
              <label htmlFor="email">Email</label>
              <input
                name="email"
                id="email"
                type="text"
                placeholder="e.g. kishan.choudhary@roguecode.com"
                value={formData.email}
                onChange={handlerForm}
              />
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <Input>
                <input
                  name="password"
                  id="password"
                  type={showPassword}
                  placeholder="**********"
                  value={formData.password}
                  onChange={handlerForm}
                />
                {showPassword === "password" ? (
                  <VisibilityIcon onClick={() => setShowPassword("text")} />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => setShowPassword("password")}
                  />
                )}
              </Input>
            </div>

            {showSpinner ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CircularProgress
                  style={{
                    color: "#ffbd00",
                  }}
                />
              </div>
            ) : (
              <button type="submit" onClick={handlerSubmit}>
                Login
              </button>
            )}
            <button disabled={showResetSpinner} onClick={handleResetPassword}>
              Reset Password
            </button>
          </form>

          <p>
            By clicking Login, you agree to our{" "}
            <a>End User License Agreement (EULA)</a>
          </p>
        </Form>
      </Right>
    </Container>
  );
};

const Container = styled.div`
  background-color: #161819;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  overflow: hidden;
`;

const Left = styled.div`
  width: 60%;
  height: 100%;
`;

const Right = styled.div`
  width: 40%;
  background-color: #fff;
  color: #000;
  height: 100%;
  /* padding: 30px; */
  padding: 50px;
  h1 {
    color: #1a1a1a;
    font-size: 34px;
  }
`;

const Circle = styled.div`
  width: 200px;
  height: 200px;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: -6%;
  left: -6%;

  &:nth-child(2) {
    top: 85%;
    left: 52%;
  }
`;

const Header = styled.div`
  position: relative;
  /* width: 100%; */
  /* max-width: 400px; */
  margin: 0 auto;
  margin-top: 150px;
  margin-left: 150px;
  /* background-color: red; */
  color: #ebecf0;
  h1 {
    font-size: 45px;
    line-height: 1.3;
  }
  p {
    font-size: 20px;
  }
`;

const Rogue = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top: -20%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 50%;
  }
`;

const Logo = styled.div`
  width: 500px;
  height: 450px;
  overflow: hidden;
  /* background-color: red; */

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
`;

const Form = styled.div`
  width: 100%;
  max-width: 400px;
  margin-top: 60px;
  color: #86869b;
  /* margin: 50px auto; */
  /* background-color: red; */
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  input {
    /* height: 35px; */
    padding: 10px 10px;
    outline: none;
    border: 1px solid #cccccc;

    &::placeholder {
      color: #86869b;
    }
  }
  label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  button {
    margin-top: 30px;
    border-radius: 5px;
    width: 100%;
    color: #fff;
    background-color: #ffbd00;
    outline: none;
    border: none;
    padding: 15px 0;
    font-size: 15px;
    cursor: pointer;
    border: 1px solid #ffbd00;
    /* rgba(255,189,0,0.5) */
    box-shadow: 0 5px 15px 0 rgba(255, 189, 0, 0.5);
    :disabled {
      cursor: not-allowed;
    }
  }

  p {
    padding: 15px 0;
  }
  a {
    color: #1c6ce5;
  }
`;

const Option = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  color: #999999;
  p {
    /* background-color: red; */
    /* display: block; */
    text-align: center;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 50%;
      right: 65%;

      width: 30%;
      height: 1px;
      background-color: #999999;
      color: #999999;
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 65%;
      width: 30%;
      height: 1px;
      background-color: #999999;
      color: #999999;
    }
  }
`;

const Btns = styled.div`
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  cursor: pointer;
  border-radius: 5px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const Input = styled.div`
  display: flex;
  flex-direction: row !important;
  border: 1px solid #cccccc;
  /* align-items: center; */
  input {
    width: 100%;
    border: none;
  }

  .MuiSvgIcon-root {
    color: #86869b;
    margin-right: 10px;
    cursor: pointer;
    margin-top: 5px;
  }
`;

export default Login;
