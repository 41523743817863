import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { PptButton } from "../../../common/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import { INPUT_IMAGE_PATH } from "../../../common/const";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },

  button: {
    backgroundColor: "#18A136",
    width: "100%",
    fontWeight: 500,
    color: "#fff",

    "&:hover": {
      backgroundColor: "#18A136",
    },
  },

  container: {
    height: "237px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    width: 390,
    borderRadius: 15,
    overflow: "hidden",
  },

  desc: {
    height: "100px",
    marginTop: 30,
    padding: "0 15px",
    marginBottom: 30,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #D9D9D9",
    padding: "10px 15px",
  },

  viewButton: {
    background: "#3673BB",
    width: 129,
    "&:hover": {
      background: "#3673BB",
      boxShadow: "none",
    },
  },
  downloadButton: {
    width: 212,
    background: "#001C65",
    "&:hover": {
      background: "#001C65",
      boxShadow: "none",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
}));

const PptCard = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Card className={classes.root}>
          <div className={classes.desc}>
            <div className={classes.heading}>
              <img src={INPUT_IMAGE_PATH.QUIZ_PPTICON} alt="ppt-icon" />
              <Typography variant="h6" style={{ fontWeight: 600 }} gutterBottom>
                PPT 1
              </Typography>
            </div>
            <Typography variant="body2" component="p">
              This quiz is a mixture of all the HTML Topics
            </Typography>
          </div>

          <div className={classes.footer}>
            <div>
              <PptButton
                className={classes.viewButton}
                variant="contained"
                startIcon={<VisibilityIcon />}
              >
                View
              </PptButton>
            </div>
            <div>
              <PptButton
                className={classes.downloadButton}
                variant="contained"
                startIcon={<GetAppIcon />}
              >
                Download
              </PptButton>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default PptCard;
