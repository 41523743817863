import React, { useEffect, useRef } from "react";
import { gsapAnimation } from "./helper";

function AnimationWrapper({ children, title = "Rogue Code" }) {
  let screen = useRef(null);
  let body = useRef(null);

  useEffect(() => {
    gsapAnimation(screen, body);
  });

  return (
    <React.Fragment>
      <div className="load-container-gsap">
        <div className="load-screen-gsap" ref={(el) => (screen = el)}>
          <h1>{title}</h1>
        </div>
      </div>
      <div data-barba="container" className="container-gsap">
        <div ref={(el) => (body = el)} className="headd-gsap">
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AnimationWrapper;
