import React from "react";
import AnimationWrapper from "../../common/AnimationWrapper";
import TrainingHero from "./TrainingHero";
import TrainingWeCode from "./TrainingWeCode";
import TrainingTab from "./TrainingTab";

function Training() {
  return (
    <AnimationWrapper title="Training">
      <TrainingHero />
      <TrainingWeCode />
      <TrainingTab />
    </AnimationWrapper>
  );
}

export default Training;
