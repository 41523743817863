import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, TextField, Typography } from "@material-ui/core";
import styled from "styled-components";
import { addShopOrders } from "../../backend/AddOrders";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  checkout: {
    height: 39,
    width: 159,
    color: "#FFFFFF",
    background: "#F6B501",
    fontWeight: "700",
    boxShadow: "none",
    textTransform: "capitalize",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#F6B501",
      boxShadow: "none",
    },
  },
}));

const CheckoutModal = ({ cartProducts }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    address: "",
    contactNo: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleModal = () => {
    setOpen((state) => !state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(cartProducts);
    const orderDetails = {
      ...formData,
      status: "Pending",
      orderDate: new Date(),
      orderId: generateRandomId(),
      products: cartProducts,
    };
    const res = await addShopOrders(orderDetails);
    if (res.success) {
      const statusRes = await fetch(
        `https://us-central1-rogue-code-portal.cloudfunctions.net/getOrderMail?email=${formData?.email}&name=${formData?.name}`
      );
      if (statusRes.status === 200) {
        toast.success(`Ordered successfully`);
        navigate("/home");
      }
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        className={classes.checkout}
        onClick={toggleModal}
      >
        Checkout
      </Button>
      {open && (
        <Modal
          className={classes.modal}
          open={open}
          onClose={toggleModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <FormContainer>
                <form onSubmit={handleSubmit}>
                  <Typography style={{ paddingBottom: 10 }} variant="h5">
                    Place Your Order
                  </Typography>
                  <StyledTextField
                    size="small"
                    label="Name"
                    name="name"
                    required
                    value={formData.name}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                  />
                  <StyledTextField
                    size="small"
                    label="Email"
                    name="email"
                    required
                    value={formData.email}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                  />
                  <StyledTextField
                    size="small"
                    label="Address"
                    name="address"
                    required
                    value={formData.address}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                  />
                  <StyledTextField
                    size="small"
                    label="Contact No."
                    name="contactNo"
                    required
                    value={formData.contactNo}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.checkout}
                  >
                    Submit
                  </Button>
                </form>
              </FormContainer>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
};

export default CheckoutModal;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 15px;
`;

function generateRandomId() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomId = "";

  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }

  return randomId;
}
