import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },
    primary: { main: "#5C36BB" },
    secondary: { main: "#4C4B47" },
    success: {
      light: "#BBFFBA",
      main: "#219653",
      dark: "#219653",
    },
    error: {
      light: "#FFBABA",
      main: "#F44336",
      dark: "#F44336",
    },
    info: {
      light: "#D3E6F7",
      main: "#41A3FC",
      dark: "#41A3FC",
    },
    warning: {
      light: "#FFF8E4",
      main: "#FFBD00",
      dark: "#FFBD00",
    },
    grey: {
      10: "#1A1A1A",
      20: "#333333",
      30: "#4D4D4D",
      40: "#676767",
      50: "#909090",
      70: "#B0B2B5",
      80: "#D3D3D3",
      90: "#D9D9D9",
      100: "#F5F6F8",
    },
  },
  typography: {
    fontFamily:
      "'Outfit', 'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 96,
      lineHeight: "116.7%",
    },
    h2: {
      fontSize: 60,
      lineHeight: "120%",
    },
    h3: {
      fontSize: 48,
      lineHeight: "116.7%",
    },
    h4: {
      fontSize: 34,
      lineHeight: "123.5%",
    },
    h5: {
      fontSize: 24,
      lineHeight: "133.4%",
    },
    h6: {
      fontSize: 20,
      lineHeight: "160%",
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: "175%",
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: "157%",
    },

    body1: {
      fontSize: 16,
      lineHeight: "150%",
      letterSpacing: "0.15%",
    },

    body2: {
      fontSize: 14,
      lineHeight: "143%",
    },
    button: {
      fontSize: "14",
      lineHeight: "114.29%",
    },
  },
});
