import React from "react";
import styled from "styled-components";
import moment from "moment";
import { addQuizAttempts } from "../backend/saveQuizAttempts";

const ConfirmSubmit = (props) => {
  const {
    showScorecard,
    showScore,
    SubmitQuiz,
    setShowScore,
    quizName,
    score,
    minutes,
    seconds,
    quizLength,
    quizTime,
    quizId,
  } = props;

  const handlerSubmit = async () => {
    const quizData = {
      quizName,
      score,
      date: moment().format("Do MMMM YYYY, h:mm:ss a"),
      time: `${quizTime / 60 - (minutes + 1)} : ${60 - seconds}`,
      min:
        quizTime / 60 - (minutes + 1) < 10
          ? quizTime / 60 - (minutes + 1)
          : quizTime / 60 - (minutes + 1),
      sec: 60 - seconds < 10 ? "0" + (60 - seconds) : 60 - seconds,
      quizLength,
      quizId,
    };

    await addQuizAttempts(quizData);
    setShowScore("close");
    SubmitQuiz();
  };

  return (
    <>
      {showScore === "open" && (
        <Container>
          <Box>
            <h1>Are you sure you want to submit?</h1>
            <h3>You won’t be able to make changes</h3>

            <Btns>
              <button onClick={handlerSubmit}>Yes</button>
              <button onClick={() => showScorecard()}>No</button>
            </Btns>
          </Box>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const Box = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 550px;
  padding: 40px 0;
  border-radius: 10px;
  text-align: center;
  h1 {
    color: #1a1a1a;
    text-align: center;
    font-size: 20px;
  }
  h3 {
    color: #666666;
    font-weight: 400;
    font-size: 18px;
  }
`;

const Btns = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;

  button {
    outline: none;
    border: none;
    padding: 15px 85px;
    background: #f2b318;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;

    &:nth-child(2) {
      background: #1a1a1a;
      color: #fff;
    }
  }
`;

export default ConfirmSubmit;
