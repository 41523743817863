import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProductDescription from "./ProductDescription";
import { ProductData } from "./ProductHeader";
import { useParams } from "react-router-dom";
import { getProductDetails } from "../../../backend/shopTab";
import { SimilarProducts } from "../../../components/SuggestProducts/SimilarProducts";
import { Footer } from "../../Home/Footer";
import LoginNavbar from "../../Home/LoginNavbar";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "41px 92px 0px 92px",
  },
}));

export const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  useEffect(() => {
    handleProduct(id);
  }, [id]);

  const handleProduct = async (id) => {
    const productData = await getProductDetails(id);
    setProduct(productData);
  };

  const classes = useStyles();
  return (
    <div>
      <LoginNavbar />
      <div className={classes.root}>
        <ProductData product={product} />
        <ProductDescription product={product} />
        <SimilarProducts />
      </div>
      <Footer />
    </div>
  );
};
