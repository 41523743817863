import React from 'react'
import styled from 'styled-components'
import {useNavigate} from "react-router-dom"
import { useSelector } from 'react-redux' 
import {selectUser} from "../features/userSlice"

const Endquiz = (props) => {
    const {handlerExit, model, showView, viewSoln} = props
    const navigate = useNavigate()
    const user = useSelector(selectUser)

    const handlerHome = () => {
        navigate("/home")
    }
  
  return (
    <>
    {
        model === "open" &&

        <Container>
    <Box>
    <h1>Are you sure you want to quit this quiz ?</h1>
    {
        !viewSoln &&
        <h3>Your progress will be deleted</h3>

    }
   

    <Btns>
        <button onClick={handlerHome}>
            Yes
        </button>

        <button onClick={() => handlerExit()}>
            No
        </button>
      </Btns>
    </Box>

    </Container>

    }

    </>
  )
}

const Container = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
align-items: center;
justify-content: center;
z-index: 100;

`

const Box = styled.div`
background-color: #fff;
width: 100%;
max-width: 550px;
padding: 40px 0;
border-radius: 10px;
text-align: center;
h1 {
    color: #1A1A1A;
    text-align: center;
    font-size: 20px;
}
h3 {
    color: #666666;
    font-weight: 400;
    font-size: 18px;
}
`;

const Btns = styled.div`
display: flex;
gap: 20px;
margin-top: 20px;
align-items: center;
justify-content: center;

button {
    outline: none;
    border: none;
    padding: 15px 85px;
    background: #F2B318;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;

    &:nth-child(2) {
        background: #1A1A1A;
        color: #fff;
    }
}
`;

export default Endquiz