import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 232,
    height: 296,
    boxShadow: "none",
  },
  media: {
    height: 216,
    paddingTop: "56.25%", // 16:9
  },
  content: {
    maxHeight: 46,
    padding: "6px 14px 6px 12px",
    marginBottom: 6,
    overflow: "hidden",
  },
  title: { fontWeight: "600", color: "#242424", fontSize: 15 },
  footer: {
    height: 33,
    padding: "0px 13px 0px 11px",
    display: "flex",
    alignItems: "center",
  },
  price: { color: "#242424", fontSize: 16, fontWeight: "600" },
  mrp: {
    fontSize: 13,
    color: "#5C5C5C",
    textDecoration: "line-through",
    paddingLeft: 5,
  },
}));

export const ProductCard = ({ products }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Link to={`/shop/product/${products?.docId}`}>
        <CardMedia
          className={classes.media}
          width={232}
          image={products?.productImage?.[0]}
        />
        <div className={classes.content}>
          <Typography variant="body2" className={classes.title}>
            {products?.productName}
          </Typography>
        </div>
        <div className={classes.footer}>
          <Typography className={classes.price} variant="body2">
            &#8377; {products?.discountPrice}
          </Typography>
          <Typography className={classes.mrp} variant="body2">
            &#8377; {products?.productMRP}
          </Typography>
        </div>
      </Link>
    </Card>
  );
};
