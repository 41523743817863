import { configureStore } from '@reduxjs/toolkit'
import  userSlice  from '../features/userSlice'
import timerSlice from '../features/timerSlice'


export default configureStore({
  reducer: {
    user : userSlice,
    timer : timerSlice,
  }
})