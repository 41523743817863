import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { FilterButton } from "../../../common/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 114,
    color: "#ffff",
    background:
      "linear-gradient(180deg, #7F73A4 0%, rgba(92, 54, 187, 0.67) 100%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 68,
    paddingRight: 77,
  },
  subHeading: {
    fontSize: 13,
    paddingTop: 5,
  },
  button: {
    display: "flex",
    gap: 66,
  },
}));

const PRODUCTS = ["Hoodie", "Bags", "T-shirt", "Bottles"];

export const ProductFilter = () => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} max-sm:h-full max-sm:p-3`}>
      <div className="max-sm:w-5/12">
        <Typography variant="h5">Shop</Typography>
        <Typography className={classes.subHeading}>Home &gt; Shop</Typography>
      </div>
      <div>
        <div
          className={`${classes.button} max-sm:flex-wrap max-sm:gap-2 max-sm:justify-end`}
        >
          {PRODUCTS.map((product, index) => (
            <FilterButton key={index} variant="contained">
              {product}
            </FilterButton>
          ))}
        </div>
      </div>
    </div>
  );
};
