// import app from "firebase/app";

import db from "../firebase";
import { collection, where, getDocs, query } from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  getAuth,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";

const auth = getAuth();

export const authenticateUser = async (email, password) => {
  try {
    const collectionRef = query(
      collection(db, "Students"),
      where("email", "==", email)
    );
    const querySnapshot = await getDocs(collectionRef);
    if (querySnapshot.docs.length) {
      const userData = querySnapshot.docs[0].data();
      const res = await signInWithEmailAndPassword(auth, email, password);
      console.log(res);
      localStorage.setItem("userUID", res?.user?.uid);
      localStorage.setItem("email", userData?.email);
      localStorage.setItem("name", userData?.studentName);
      localStorage.setItem("docId", userData?.docId);
      return { success: true, authState: res };
    } else {
      return { success: false, message: "User Does Not Exist." };
    }
  } catch (error) {
    console.log(error);
    alert(error);
  }
};

//  check if userUID is invalid and set user log out
export const checkUserLoggedOutState = () => {
  console.log("User is Logged-In/Logged-Out");
  return (
    localStorage.getItem("userUID") === undefined ||
    localStorage.getItem("userUID") === null
  );
};

export const isUserLoggedIn = () => {
  const userUid = localStorage.getItem("userUID");
  const email = localStorage.getItem("email");
  const name = localStorage.getItem("name");
  const docId = localStorage.getItem("docId");

  return !!userUid && !!email && !!name && !!docId;
};

//log out user and remove userUID & previlages
export const logOutUser = async () => {
  try {
    await signOut(auth);
    localStorage.clear();
    window.location.href = "/";
    //when user successfully signed in
    return { success: true };
  } catch (error) {
    console.log(error); //signIn Failed wrong password not exists
    return { success: false, message: error.message };
  }
};

//Get Id token of current user for API
export const getToken = async () => {
  const idToken = await auth.currentUser.getIdToken();
  return idToken;
};

export const resetPassword = async (email) => {
  if (!email) {
    return { success: false, message: "Please add email address" };
  }
  const ref = query(collection(db, "Students"), where("email", "==", email));
  const querySnapshot = await getDocs(ref);

  if (querySnapshot.empty) {
    return { success: false, message: "User do not exist" };
  }

  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, message: error.message };
  }
};
