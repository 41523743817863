import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ImagePreview } from "./PreviewImage";

const useStyles = makeStyles((theme) => ({
  mainImage: {
    objectFit: "cover",
    border: "1px solid #C0C0C0",
  },
  subImages: {
    display: "flex",
    gap: 21,
    padding: "38px 0px",
  },
  subImage: {
    objectFit: "contain",
    border: "1px solid #C0C0C0",
  },
}));

export const ProductMedia = ({ product }) => {
  const classes = useStyles();
  const { productImage } = product;
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handleOpen = (image) => {
    setPreviewImage(image);
    setOpenPreview((currentState) => !currentState);
  };

  const handleState = () => {
    setOpenPreview((currentState) => !currentState);
  };

  return (
    <>
      <ImagePreview
        open={openPreview}
        handleClose={handleState}
        previewImage={previewImage}
      />
      <div>
        <img
          className={classes.mainImage}
          onClick={() => handleOpen(productImage?.[0])}
          alt={productImage?.[0]}
          src={productImage?.[0]}
          width={515}
          height={472}
        />
        {productImage?.length > 1 ? (
          <div className={classes.subImages}>
            {productImage
              ?.map((imageLink) => (
                <img
                  className={classes.subImage}
                  onClick={() => handleOpen(imageLink)}
                  alt={imageLink}
                  src={imageLink}
                  width={112}
                  height={148}
                />
              ))
              .slice(1)}
          </div>
        ) : null}
      </div>
    </>
  );
};
