import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  AskHeader: {
    height: 240,
    backgroundColor: "#5C36BB",
    width: "100%",
    textAlign: "center",
  },
  heading: {
    paddingTop: 50,
    color: "#fff",
  },
  textField: {
    paddingTop: 20,
    height: 42,
    width: 649,
  },
  inputTextField: {
    background: "#FFFFFF4D",
  },
  searchBar: {
    margin: "15px auto",
    height: 45,
    width: "100%",
    maxWidth: 600,
    background: "rgba(255, 255, 255, 0.3)",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    padding: "0px 15px",
  },
}));

export const AskHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.AskHeader}>
      <div className={classes.heading}>
        <Typography variant="h3" style={{ fontWeight: 700 }}>
          Ask?
        </Typography>
        <Typography variant="h6">
          Ask your doubt, we got you covered!
        </Typography>
        <TextField
          className={classes.textField}
          size="small"
          placeholder="Search your question...."
          variant="outlined"
          InputProps={{
            startAdornment: <SearchIcon />,
            className: classes.inputTextField,
          }}
        />
      </div>
    </div>
  );
};
