import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { ProductMedia } from "./ProductMedia";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import { ProductButton } from "../../../common/Button";
import ProductContext from "../../../components/Context/ProductContext";

const useStyles = makeStyles((theme) => ({
  back: {
    display: "flex",
    cursor: "pointer",
    paddingBottom: 58,
  },
  container: {
    display: "flex",
    gap: 86,
  },
  productTitle: {
    fontWeight: "700",
  },
  highlighted: {
    color: "#002E63",
  },
  subDetails: {
    paddingTop: 20,
  },
  quantityInput: {
    width: 200,
    padding: "18px 0px",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  button: {
    display: "flex",
    gap: 37,
  },
  cartButton: {
    color: "#5C36BB",
    fontWeight: "700",
  },
  buyButton: {
    background: "#5C36BB",
    color: "#ffff",
    "&:hover": {
      background: "#5C36BB",
    },
  },
}));

export const ProductData = ({ product }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { addToCartClick } = useContext(ProductContext);

  const [quantity, setQuantity] = useState(1);

  const handlerBack = () => {
    window.history.back();
  };

  const handleQuantity = (e) => {
    const value = e.target?.valueAsNumber;
    setQuantity(value ? value : 1);
  };

  return (
    <div>
      <div className={classes.back}>
        <ArrowBackIcon onClick={handlerBack} />
        <Typography style={{ marginLeft: 10 }}>
          {product.productName}
        </Typography>
      </div>
      <div className={classes.container}>
        <ProductMedia product={product} />
        <div>
          <Typography className={classes.productTitle} variant="h4">
            {product?.productName}
          </Typography>
          <div className={classes.subDetails}>
            <Typography variant="h6" gutterBottom>
              Product Code:{" "}
              <span className={classes.highlighted}>
                {product?.productCode}
              </span>
            </Typography>
            <Typography variant="h6" gutterBottom>
              Category:{" "}
              <span className={classes.highlighted}>
                {product?.productCategory}
              </span>
            </Typography>
            {parseFloat(product?.stock) <= 10 ? (
              <Typography
                variant="h6"
                style={{ color: "#E10000" }}
              >{`Hurry up !! Only ${product?.stock} ${product?.productCategory} Left`}</Typography>
            ) : null}
            <TextField
              className={classes.quantityInput}
              variant="outlined"
              type="number"
              value={quantity}
              onChange={handleQuantity}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={quantity <= 1}
                      onClick={() => setQuantity(quantity - 1)}
                    >
                      <RemoveOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setQuantity(quantity + 1)}>
                      <AddOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="h4" gutterBottom>
              MRP: <span>₹{product?.discountPrice}</span>{" "}
              <s
                style={{
                  fontSize: 20,
                  color: "#565959",
                  marginLeft: "5px",
                }}
              >
                ₹{product?.productMRP}
              </s>
            </Typography>
          </div>
          <div className={classes.button}>
            <ProductButton
              className={classes.cartButton}
              onClick={() =>
                addToCartClick(product?.docId, quantity, product["productName"])
              }
            >
              Add to Cart
            </ProductButton>
            <ProductButton
              onClick={() => {
                addToCartClick(
                  product?.docId,
                  quantity,
                  product["productName"]
                );
                navigate("/shopping-cart");
              }}
              className={classes.buyButton}
            >
              Buy Now
            </ProductButton>
          </div>
        </div>
      </div>
    </div>
  );
};
