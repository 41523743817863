export const INPUT_IMAGE_PATH = {
  QUIZ_CALENDER: "/assets/Quiz/calender.svg",
  QUIZ_STARS: "/assets/Quiz/stars.svg",
  QUIZ_MEDAL: "/assets/Quiz/Medal.svg",
  QUIZ_BANNER: "/assets/Quiz/background.png",
  QUIZ_QUIZES: "/assets/Quiz/quizes.svg",
  QUIZ_ASSIGNEMNT: "/assets/Quiz/assignemnt.svg",
  QUIZ_PPT: "/assets/Quiz/ppt.svg",
  QUIZ_PPTICON: "/assets/Quiz/ppt-icon.svg",
  QUIZ_BARS: "/assets/Quiz/bars.svg",
  QUIZ_ARROW: "/assets/Quiz/arrow.svg",
  QUIZ_HTML: "/assets/Quiz/html-circle.svg",
  QUIZ_CSS: "/assets/Quiz/css-circle.svg",
  QUIZ_JS: "/assets/Quiz/js-circle.svg",
  QUIZ_REACT: "/assets/Quiz/react-circle.svg",
  QUIZ_RIGHT: "/assets/Quiz/correct.svg",
  LOGIN: "/assets/Login/login.svg",
  LOGIN_ROGUE: "/assets/Login/rogue-logo.svg",
  LOGIN_VECTOR: "/assets/Login/vector2.svg",
  LOGIN_COMPILER: "/assets/Login/compiler.svg",
  FOOTER_LOGO: "/assets/Login/footer-logo.svg",
  LOGIN_LOCATION: "/assets/Login/location.svg",
  LOGIN_MAIL: "/assets/Login/mail.svg",
};

export const socialMedia = [
  {
    id: 1,
    logo: "/assets/Login/linkedin.svg",
  },
  {
    id: 2,
    logo: "/assets/Login/insta.svg",
  },
  {
    id: 3,
    logo: "/assets/Login/yt.svg",
  },
  {
    id: 4,
    logo: "/assets/Login/facebook.svg",
  },
  {
    id: 5,
    logo: "/assets/Login/twitter.svg",
  },
];

export const QuizData = [
  {
    id: 1,
    title: "HTML",
    description: `A powerful language for Web Development.`,
    image: "/assets/Quiz/html.svg",
    circlelogo: "/assets/Quiz/html-circle.svg",
  },
  {
    id: 2,
    title: "CSS",
    description:
      "CSS is language which helps you do styling of Website and apps",
    image: "/assets/Quiz/css.svg",
    circlelogo: "/assets/Quiz/css-circle.svg",
  },
  {
    id: 3,
    title: "JavaScript",
    description: "JavaScript makes dynamic functionality easily achievable",
    image: "/assets/Quiz/js.svg",
    circlelogo: "/assets/Quiz/js-circle.svg",
  },
  {
    id: 4,
    title: "REACT JS",
    description: "A powerful language for Web Development.",
    image: "/assets/Quiz/react.svg",
    circlelogo: "/assets/Quiz/react-circle.svg",
  },
];

export const interviewData = [
  {
    id: 1,
    title: "TCS Question Set",
    description: `A collection of quality created quiz and mcq of html`,
    image: "/assets/Quiz/tcs.svg",
    banner: "/assets/Quiz/banner.svg",
    time: 1,
    questions: 50,
  },
  {
    id: 2,
    title: "Wipro Question Set",
    description: "A collection of quality created quiz and mcq of css",
    image: "/assets/Quiz/wipro.svg",
    banner: "/assets/Quiz/banner.svg",
    time: 1,
    questions: 50,
  },
  {
    id: 3,
    title: "Infosys Question Set",
    description: "A collection of quality created quiz and mcq of Js",
    image: "/assets/Quiz/infosys.svg",
    banner: "/assets/Quiz/banner.svg",
    time: 1,
    questions: 50,
  },
  {
    id: 4,
    title: "Congnizant Question Set",
    description: "A collection of quality created quiz and mcq of React.",
    image: "/assets/Quiz/cognizant.svg",
    banner: "/assets/Quiz/banner.svg",
    time: 1,
    questions: 50,
  },
];

export const navbarList = [
  {
    id: 1,
    name: "Jamboard",
    link: "/jamboard",
  },
  // {
  //   id: 6,
  //   name: "Contact",
  //   link: "/contact",
  // },
  {
    id: 5,
    name: "Login",
    link: "/login",
  },
];

export const cardData = [
  {
    id: 1,
    title: "Learn Web Development",
    img: "/assets/Login/learn-web.svg",
    color: "#00CC76",
  },
  {
    id: 2,
    title: "Learn App Development",
    img: "/assets/Login/learn-android.svg",
    color: "#FFF3AA",
  },
  {
    id: 3,
    title: "Learn Full-Stack Development",
    img: "/assets/Login/learn-fullstack.svg",
    color: "#62C3FF",
  },
];

export const formatDate = (rowDate) => {
  const date = new Date(rowDate?.seconds * 1000);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};
