import React from "react";
import { FaCheck } from "react-icons/fa";
import details from "../../assets/TrainingPage/details-1-new.png";

const TrainingWeCode = () => {
  return (
    <div className=" w-full py-12">
      <div className="container mx-auto  flex flex-col md:flex-row  gap-2 px-2 md:px-6">
        <div className="w-full md:w-1/3 h-full  flex justify-end items-center md:pr-16">
          <img src={details} className=" h-full md:h-[50vh]" alt="" />
        </div>

        <div className=" w-full md:w-2/3 flex flex-col gap-3">
          <h3 className="text-blue-600 font-semibold text-3xl">
            Rogue Code Academy - We Code,We Compile,We Run.
          </h3>
          <p className="text-xl text-slate-600">
            Our goal is to be a haven for aspiring coders and hackers who
            haven't got a clue where to start in the field of programming and
            also for those who want to skyrocket their career in the field of
            programming by acquiring additional knowledge.
          </p>

          <ul className="flex flex-col gap-3">
            <li className="flex gap-2 items-center">
              <FaCheck className="text-yellow-400" />
              <span className="text-md text-slate-500">
                Unique and Fun Approach To Learn Coding.
              </span>
            </li>
            <li className="flex gap-2 items-center">
              <FaCheck className="text-yellow-400" />
              <span className="text-md text-slate-500">
                Focus On Practical And Hands-On Training.
              </span>
            </li>
            <li className="flex gap-2 items-center">
              <FaCheck className="text-yellow-400" />
              <span className="text-md text-slate-500">
                Experienced Trainers And Mentors.
              </span>
            </li>
            <li className="flex gap-2 items-center">
              <FaCheck className="text-yellow-400" />
              <span className="text-md text-slate-500">
                Young Team With A Willingness To Help You In Every Way.
              </span>
            </li>
            <li className="flex gap-2 items-center">
              <FaCheck className="text-yellow-400" />
              <span className="text-md text-slate-500">
                {" "}
                Covering Modern Technologies And Motivating Research.
              </span>
            </li>
            <li className="flex gap-2 items-center">
              <FaCheck className="text-yellow-400" />
              <span className="text-md text-slate-500">
                Making Learning To Code Accessible, Affordable And Fun.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TrainingWeCode;
