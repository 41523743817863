import React from "react";
import Home from "../components/Home";
import Dashbord from "../pages/Dashbord";
import Login from "../pages/Login";
import Attempts from "../pages/Attempts";
import Quiz from "../pages/Quiz/Quiz";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Notfound from "../pages/Notfound";
import Blogs from "../pages/Blogs";
import PreviewQuiz from "../pages/ViewActiveQuiz/PreviewQuiz";
import BlogsView from "../components/BlogVIews/BlogsView";
import CourseQuiz from "../pages/SetQuiz/CourseQuiz";
import LoginPage from "../pages/Home/LognPage";
import { Products } from "../pages/CoreShopTab/CoreProductsTab/Products";
import { ProductDetails } from "../pages/CoreShopTab/CoreViewProduct/ProductDetails";
import { Events } from "../pages/Events/Events";
import { ViewEvent } from "../pages/Events/PreviewEvents/ViewEvent";
import { isUserLoggedIn } from "../backend/authenticateUser";
import { ShoppingCart } from "../pages/ShoppingCart/ShoppingCart";
import { Ask } from "../pages/Ask/Ask";
import { Refer } from "../pages/ReferAndEarn/Refer";
import { Courses } from "../pages/CoreCourses/Courses";
import { CourseDetails } from "../pages/CourseDetails/CourseDetails";
import { EditBio } from "../pages/EditBio/EditBio";
import Jamboard from "../pages/Jamboard/Jamboard";
import Training from "../pages/Training/Training";
import WebDev from "../pages/WebDev/WebDev";

const PrivateRoute = ({ children }) => {
  // Add your own authentication on the below line.

  const isLoggedIn = isUserLoggedIn(); //AuthService.isLoggedIn();

  return isLoggedIn ? children : <Navigate to="/login" />;
};

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/jamboard" element={<Jamboard />} />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              {/* <Dashbord /> */}
              <Courses />
            </PrivateRoute>
          }
        />
        <Route
          path="/attempts"
          element={
            <PrivateRoute>
              <Attempts />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/course-section"
          element={
            <PrivateRoute>
              <Quiz />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/course/:id"
          element={
            <PrivateRoute>
              <PreviewQuiz />
            </PrivateRoute>
          }
        />
        <Route
          path="/course/:id/quiz/:quizId/:quizDocId"
          element={
            <PrivateRoute>
              <CourseQuiz />
            </PrivateRoute>
          }
        />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<BlogsView />} />
        <Route
          path="/questions/:id"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route path="/shop" element={<Products />} />
        <Route path={"/shop/product/:id"} element={<ProductDetails />} />
        <Route path={"/shopping-cart"} element={<ShoppingCart />} />
        <Route path={"/events"} element={<Events />} />
        <Route path={"/events/:id"} element={<ViewEvent />} />
        <Route
          path={"/ask"}
          element={
            <PrivateRoute>
              <Ask />
            </PrivateRoute>
          }
        />
        <Route
          path={"/refer"}
          element={
            <PrivateRoute>
              <Refer />
            </PrivateRoute>
          }
        />
        <Route
          path="/course"
          element={
            <PrivateRoute>
              <Courses />
            </PrivateRoute>
          }
        />
        <Route path="/course-details/:id" element={<CourseDetails />} />
        <Route
          path="/edit-bio"
          element={
            <PrivateRoute>
              <EditBio />
            </PrivateRoute>
          }
        />
        <Route path="/training" element={<Training />} />
        <Route path="/web-dev" element={<WebDev />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Notfound />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};
