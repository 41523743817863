import React from "react";
import trainingHero from "../../assets/TrainingPage/training-hero-img.png";
import image from "../../assets/TrainingPage/hero-bg.jpg";

import { FaWhatsapp } from "react-icons/fa";
import TrainingHeroWaves from "./TrainingHeroWaves";

function TrainingHero() {
  return (
    <div
      className=" w-full "
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className=" w-[100vw] relative z-20 bg-[#0C12A0]">
        <div className=" max-w-6xl mx-auto px-4 md:px-6 h-full flex justify-center items-center md:mt-0">
          <div className=" mt-12 min-h-screen md:min-h-[70vh] w-full flex items-center justify-between px-2 md:px-6">
            <div className="w-[60%] flex-1 h-[80vh] md:h-full  flex flex-col items-start gap-12">
              <h1 className="text-white font-bold text-5xl">
                Learn The Art Of Coding With Rogue Code
              </h1>
              <p className="text-lg md:text-2xl text-white/70">
                We are an aspiring and motivated team of Talented Designers and
                Developers who believe in the making of Quality Software that
                impacts People in a Positive way.
              </p>
              <button className="flex items-center gap-2 rounded-full py-2 md:py-6 px-8 bg-yellow-500 hover:bg-green-500 transition-all duration-300 ease-linear">
                <FaWhatsapp className="w-6 h-6" />
                <span className="text-2xl font-semibold text-white">
                  Ping Us!
                </span>
              </button>
            </div>

            <div className="w-[40%] h-full hidden md:block">
              <img className="animated" src={trainingHero} alt="" />
            </div>
          </div>
        </div>
        <TrainingHeroWaves />
      </div>
    </div>
  );
}

export default TrainingHero;
