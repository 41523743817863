import { doc, updateDoc } from "firebase/firestore";
import db from "../../firebase";

export const updateUserDetails = async (payload) => {
  const { docId: userId } = payload;

  try {
    const docRef = doc(db, "Students", userId);
    await updateDoc(docRef, payload);
    return { successful: true, message: "Success" };
  } catch (error) {
    console.log(error);
    return { successful: false, message: error };
  }
};
