import { Button, Card, CardMedia, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: 60,
    top: 130,
    zIndex: 999,
    height: 362,
    width: 410,
    borderRadius: 10,
    padding: "15px 15px 25px",
    boxShadow: "2px 4px 30px 0px #00000040",
  },
  media: {
    height: 160,
    width: 381,
    borderRadius: 4,
    margin: "0px 15",
  },
  detailsContainer: {
    padding: "19px 0px 15px",
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
  courseName: {
    textTransform: "uppercase",
    color: "#F6B501",
    fontWeight: "700",
  },
  subDetails: {
    display: "flex",
    gap: 12,
    fontWeight: "500",
  },
  enrollButton: {
    background: "#5C36BB",
    height: 40,
    width: 381,
    borderRadius: 0,
    color: "#FFFFFF",
    fontWeight: "600",
    "&:hover": {
      background: "#5C36BB",
    },
  },
  buttonContainer: {
    paddingTop: 29,
  },
}));

export const CourseDetailsCard = () => {
  const classes = useStyles();
  const courseImage =
    "https://s3-alpha-sig.figma.com/img/e9e7/8a67/4551ee71026f3a31e49b22ec115f1285?Expires=1684713600&Signature=VHgkk-gsoQyqawEaf6fzj2wMjL9uR2LOnBag29rywsV6CCwp9bvPDzXz5vGjXNK8LARlTCj5jjQ00X39uMsvMPzAc8PbKiTTzQuZX~Ayn0SNhDVDXcQbX0mDOHAQoB6ixKKtY0qmaPSReN7TK2heqv-PdtmlQCyncFN0PxIbp34NBs6J~HqlpC4hYnwSTFNWz~eHxVGv49sJd3QKtDBBqjXUUvpryLMIJE9ChotsaQi9e-Mqq3JDIH54fpu3IbYwUJcL1cPKnqEDVnIHg7LcMt5a7uz2rDPJcPTTwJsvdF52O32gGSXX-De6pEytDTb~Kwr~2GzMLYO-39bn4HoWZQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4";

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={courseImage} />
      <div className={classes.buttonContainer}>
        <Button className={classes.enrollButton}>Enroll for Courses</Button>
      </div>
      <div className={classes.detailsContainer}>
        <Typography className={classes.subDetails}>
          <AccessibilityNewIcon />
          <span className={classes.courseName}>12-15</span>Lectures
        </Typography>
        <Typography className={classes.subDetails}>
          <PeopleAltIcon />
          <span className={classes.courseName}>600+</span>Student enrolled
        </Typography>
      </div>
    </Card>
  );
};
