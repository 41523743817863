import db from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

const blogsRef = query(collection(db, "Blogs"));

export const getBlogs = async () => {
  const blogsArr = [];
  try {
    const querySnapshot = await getDocs(blogsRef);
    querySnapshot.forEach((doc) => {
      blogsArr.push(doc.data());
    });
  } catch (error) {
    console.log(error);
  }

  return blogsArr;
};

export const getEvents = async () => {
  const allEvents = [];
  const eventsRef = query(collection(db, "Events"));
  try {
    const querySnapshot = await getDocs(eventsRef);
    querySnapshot.forEach((doc) => {
      allEvents.push(doc.data());
    });
    return { successful: true, data: allEvents };
  } catch (error) {
    console.log(error);
    return { successful: false, message: "There is something wrong" };
  }
};

export const getEventData = async (id) => {
  const docRef = doc(db, "Events", id);
  const docSnap = await getDoc(docRef);

  console.log(docSnap.data());

  if (!docSnap.exists()) {
    return null;
  }
  return docSnap.data();
};

export const getCartProductData = async (ids) => {
  const products = [];
  const collectionRef = collection(db, "Products");
  const productRef = query(collectionRef, where("docId", "in", ids));
  try {
    const querySnapshot = await getDocs(productRef);
    querySnapshot.forEach((doc) => {
      products.push(doc.data());
    });
    return { successful: true, data: products };
  } catch (error) {
    console.log(error);
    return { successful: false, message: "There is something wrong" };
  }
};

export const getCourses = async (studentId) => {
  try {
    //first fetch all batch related to student
    let courseIds = [];
    const batchRef = query(
      collection(db, "Batch"),
      where("batchStudents", "array-contains", studentId)
    );
    const batchQuerySnapshot = await getDocs(batchRef);
    batchQuerySnapshot.forEach((doc) => {
      const { batchCourses = [] } = doc.data();
      courseIds = [...courseIds, ...batchCourses];
    });

    //fetch courses related to that batches
    const courses = [];
    const coursesRef = query(
      collection(db, "Course"),
      where("courseId", "in", courseIds)
    );
    const querySnapshot = await getDocs(coursesRef);
    querySnapshot.forEach((doc) => {
      courses.push(doc.data());
    });
    return { successful: true, data: courses };
  } catch (error) {
    console.log(error);
    return { successful: false, message: "There is something wrong" };
  }
};

export const getQuizAttemptsData = async () => {
  const userId =
    localStorage.getItem("userUID") ?? localStorage.getItem("docId");
  const attemptsRef = query(
    collection(db, "Attempts"),
    where("userId", "==", userId)
  );
  const attemptsData = [];
  try {
    const querySnapshot = await getDocs(attemptsRef);
    querySnapshot.forEach((doc) => {
      attemptsData.push({ ...doc.data(), id: doc.id });
    });
  } catch (error) {
    console.log(error);
  }

  return attemptsData;
};
