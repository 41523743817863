import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { INPUT_IMAGE_PATH, socialMedia } from "../../common/const";

const useStyles = makeStyles({
  footer: {
    background: "#000000",
    color: "#FFFFFF",
    padding: "30px 0px",
  },
  footerFlex: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    margin: "0px auto",
    position: "relative",
    padding: "50px 176px 50px 75px",
  },
  commonFlex: {
    display: "flex",
    alignItems: "center",
    gap: 26,
  },
  links: {
    padding: "4px 0px",
  },
});

export const Footer = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.footer} max-sm:p-5`}>
      <div className={`${classes.footerFlex} max-sm:p-0 max-sm:gap-7`}>
        <div>
          <div className={classes.commonFlex}>
            <img
              src={INPUT_IMAGE_PATH.FOOTER_LOGO}
              alt=""
              height={34}
              width={34}
            />
            <Typography>Rogue Code</Typography>
          </div>

          <div
            className={`${classes.commonFlex} max-sm:pt-5`}
            style={{ paddingTop: 42 }}
          >
            {socialMedia.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item.logo}
                  alt=""
                  style={{ width: 25, height: 20 }}
                />
              );
            })}
          </div>

          <div className="max-sm:mt-5" style={{ marginTop: 50 }}>
            <div className={classes.commonFlex} style={{ gap: 30 }}>
              <img src={INPUT_IMAGE_PATH.LOGIN_LOCATION} alt="" />
              <Typography>
              25, Dattani Trade Centre, Near New BMC Office, <br/> Shanti Nagar, Borivali West, Mumbai, Maharashtra 400092
              </Typography>
            </div>

            <div
              className={`${classes.commonFlex} max-sm:mt-5`}
              style={{ gap: 30, marginTop: 30 }}
            >
              <img src={INPUT_IMAGE_PATH.LOGIN_MAIL} alt="" />
              <Typography>contact@roguecode.in</Typography>
            </div>
          </div>
        </div>
        <div className={`${classes.footerRight} max-sm:w-full`}>
          <div
            className="max-sm:w-full max-sm:justify-between max-sm:gap-0"
            style={{ display: "flex", gap: 124 }}
          >
            <div>
              <Typography style={{ fontWeight: 700, paddingBottom: 27 }}>
                COMPANY
              </Typography>
              <Typography className={classes.links}>About Us</Typography>
              <Typography className={classes.links}>Courses</Typography>
              <Typography className={classes.links}>Quiz</Typography>
            </div>

            <div>
              <Typography style={{ fontWeight: 700, paddingBottom: 27 }}>
                COMPANY
              </Typography>
              <Typography className={classes.links}>About Us</Typography>
              <Typography className={classes.links}>Courses</Typography>
              <Typography className={classes.links}>Quiz</Typography>
            </div>

            <div>
              <Typography style={{ fontWeight: 700, paddingBottom: 27 }}>
                PRODUCTS
              </Typography>
              <Typography className={classes.links}>Shop</Typography>
              <Typography className={classes.links}>Hoodies</Typography>
              <Typography className={classes.links}>Business</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
