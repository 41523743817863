import React from "react";

function WebDevPricing() {
  return (
    <div className=" w-full pb-16 relative">
      <div className=" max-w-7xl mx-auto h-full  px-4 md:px-6">
        <div className="section-title pt-8" data-aos="fade-up">
          <h2>Pricing</h2>
          <p>Website Design And Development</p>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center mt-8 space-y-6 md:space-y-0 md:space-x-6">
          <div className=" shadow-lg rounded-xl w-full md:w-1/3">
            <div className="bg-gray-100 text-center rounded-t-lg ">
              <h3 className="text-xl font-semibold py-2 md:py-4 ">
                Static Website
              </h3>
            </div>
            <ul className="mt-2 space-y-4 text-center p-6">
              <li>Custom Design</li>
              <li>Responsive Design</li>
              <li>Domain and Deluxe Hosting</li>
              <li>Basic SEO</li>
              <li className="text-gray-400 line-through">
                Contact Form - Php Mailer
              </li>
              <li className="text-gray-400 line-through">Optimized Coding</li>
              <li className="text-gray-400 line-through">CMS</li>
              <li className="text-gray-400 line-through">Payment Gateway</li>
            </ul>

            <div className="bg-gray-100 py-2 md:py-6 text-center rounded-b-xl">
              <button className=" bg-yellow-500 text-white py-2 px-4 md:px-8 rounded-full hover:bg-green-500 transition-all duration-300 ease-linear">
                Get Quote
              </button>
            </div>
          </div>
          <div className=" shadow-lg rounded-xl w-full md:w-1/3">
            <div className="bg-gray-100 text-center rounded-t-lg ">
              <h3 className="text-xl font-semibold py-2 md:py-4 ">
                Dynamic Website
              </h3>
            </div>
            <ul className="mt-2 space-y-4 text-center p-6">
              <li>Custom Design</li>
              <li>Responsive Design</li>
              <li>Domain and Deluxe Hosting</li>
              <li>Advanced SEO</li>
              <li>Contact Form - Php Mailer</li>
              <li>Optimized Coding</li>
              <li>CMS</li>
              <li className="text-gray-400 line-through">Payment Gateway</li>
            </ul>
            <div className="bg-gray-100 py-2 md:py-6 text-center rounded-b-xl">
              <button className=" bg-yellow-500 text-white py-2 px-4 md:px-8 rounded-full hover:bg-green-500 transition-all duration-300 ease-linear">
                Get Quote
              </button>
            </div>
          </div>
          <div className="shadow-lg rounded-xl w-full md:w-1/3 relative">
            <div className="absolute top-0 right-0 bg-yellow-500 text-white text-sm px-2 py-1 rounded-bl-lg">
              Business
            </div>
            <div className="bg-gray-100 text-center rounded-t-lg ">
              <h3 className="text-xl font-semibold py-2 md:py-4 ">
                E-Commerce
              </h3>
            </div>
            <ul className="mt-2 space-y-4 text-center p-6">
              <li>Custom Design</li>
              <li>Responsive Design</li>
              <li>Domain and Deluxe Hosting</li>
              <li>Advanced SEO</li>
              <li>Contact Form - Php Mailer</li>
              <li>Optimized Coding</li>
              <li>CMS</li>
              <li>Payment Gateway</li>
            </ul>
            <div className="bg-gray-100 py-2 md:py-6 text-center rounded-b-xl">
              <button className=" bg-yellow-500 text-white py-2 px-4 md:px-8 rounded-full hover:bg-green-500 transition-all duration-300 ease-linear">
                Get Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebDevPricing;
