import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import quizmap from "./Quizmap";
import SearchIcon from "@material-ui/icons/Search";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link as RouterLink } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as AskIcon } from "../global/navbar/ask.svg";
import { ReactComponent as ReferIcon } from "../global/navbar/refer.svg";
import { SvgIcon } from "@material-ui/core";
import ProductContext from "./Context/ProductContext";
import { isUserLoggedIn, logOutUser } from "../backend/authenticateUser";
import Hamburger_Icon from "../assets/Hamburger_icon.svg";
import UserAvatar from "./Avatar";

const Navs = [
  // {
  //   id: 1,
  //   title: "Quiz",
  //   link: "/course-section",
  // },
  {
    id: 1,
    title: "Course",
    link: "/course",
  },
  {
    id: 2,
    title: "Total Attempts",
    link: "/attempts",
  },
  // {
  //   id: 3,
  //   title: "Blogs",
  //   link: "/blogs",
  // },
];

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#F2B318",
    fontWeight: 600,
    fontSize: " 15px",
    textTransform: "capitalize",
  },
  compilerLink: {
    color: "#000",
  },
  styledButton: {
    color: "#f6b501",
    height: 42,
    borderRadius: 32,
    boxShadow: "0px 0px 7px 0px #00000045",
    padding: "0px 15px",
  },

  links: {
    margin: 0,
    padding: "13px 10px",
    transition: "all 0.3s ease-in-out",

    "&:hover": {
      borderBottom: "3px solid #5221E6 !important",
    },
  },
}));

const Navbar = ({ setSearch, setQuizArr }) => {
  // const [navLink, setNavLink] = useState("")
  const userName = localStorage.getItem("name");
  const isLoggedIn = isUserLoggedIn();

  const location = useLocation();
  const [form, setForm] = useState({
    name: "",
  });
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formSet = (e) => {
    const { name, value } = e.target;

    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    if (value === "") {
      setQuizArr(quizmap);
    } else {
      const filterData = quizmap.filter((item) => {
        return item.link.toLowerCase().includes(value.toLowerCase());
      });
      setQuizArr(filterData);
    }
  };

  // console.log(navLink)
  return (
    <>
      <Container>
        <Nav className="max-sm:hidden">
          <Logo>
            <Link to="/home">
              <img src="/assets/rogue-logo.jpeg" alt="" />
            </Link>
          </Logo>
          <NavList>
            {Navs.map((item) => {
              return (
                <Link
                  key={item.id}
                  className={classes.links}
                  to={item.link}
                  style={{
                    borderBottom:
                      item.link === location.pathname
                        ? "3px solid #F2B318"
                        : "3px solid transparent",
                  }}
                >
                  {item.title}
                </Link>
              );
            })}
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className={classes.button}
            >
              Online Compiler
            </Button>
            <Link
              key="shop"
              to={"/shop"}
              style={{
                borderBottom:
                  "/shop" === location.pathname
                    ? "3px solid #F2B318"
                    : "3px solid transparent",
              }}
              className={classes.links}
            >
              Shop
            </Link>
            {/* <Link
              key="events"
              to={"/events"}
              style={{
                borderBottom:
                  "/events" === location.pathname
                    ? "3px solid #F2B318"
                    : "3px solid transparent",
              }}
              className={classes.links}
            >
              Events
            </Link> */}

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <a
                  href="https://rc-js-compiler.netlify.app/"
                  target="_blank"
                  className={classes.compilerLink}
                >
                  HTML
                </a>
              </MenuItem>
            </Menu>
          </NavList>
          {/* <Input>
            <SearchIcon />
            <input
              type="text"
              placeholder="search...."
              name="name"
              value={form.name}
              onChange={formSet}
            />
          </Input> */}
          <div style={{ display: "flex", gap: 14 }}>
            <Link to={"/ask"}>
              <Button
                startIcon={<SvgIcon component={AskIcon} />}
                className={classes.styledButton}
              >
                Ask?
              </Button>
            </Link>
            <Link to={"/refer"}>
              <Button
                startIcon={<SvgIcon component={ReferIcon} />}
                className={classes.styledButton}
              >
                Refer and Earn
              </Button>
            </Link>
          </div>
          {isLoggedIn && <UserAvatar />}
        </Nav>
        <div className="sm:hidden">
          <MobileHeader />
        </div>
      </Container>
    </>
  );
};

const MobileHeader = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar((state) => !state);
  };

  return (
    <div>
      <div className="flex justify-between w-full pr-8">
        <Link to="/home">
          <img
            className="m-auto w-[100px] py-4 max-sm:pt-0 max-sm:m-0"
            width={100}
            src="/assets/rogue-logo.jpeg"
            alt=""
          />
        </Link>
        <img
          className="sm:hidden"
          onClick={toggleSidebar}
          src={Hamburger_Icon}
          alt="Hamburger_Icon"
        />
      </div>
      <div className={`${showSidebar ? "block" : "hidden"} sm:block`}>
        <NavbarItems />
      </div>
    </div>
  );
};

const activeTabStyle =
  "rounded-2xl py-px text-primary bg-secondary shadow-[0.95px_4.9px_7.2px_0px_#00000059] transition-colors duration-300 ease-in-out";

const NavbarItems = () => {
  const currentPage = window.location.pathname;
  return (
    <div className="max-sm:p-8 max-sm:pb-0">
      <div className="flex justify-between items-center mt-4 mx-auto bg-primary text-secondary h-6 w-[700px] rounded-2xl font-dm-sans max-sm:justify-center max-sm:w-full max-sm:h-full max-sm:flex-col max-sm:p-3 max-sm:gap-2 max-sm:m-0">
        {TABS.map(({ tabName, tabUrl }, index) => {
          const isActive = currentPage === tabUrl;
          return (
            <Link
              to={tabUrl}
              key={index}
              className={`text-sm text-center font-bold px-6 ${isActive ? activeTabStyle : null
                }`}
            >
              {tabName}
            </Link>
          );
        })}
        <button onClick={logOutUser}>Logout</button>
      </div>
    </div>
  );
};

const TABS = [
  {
    tabName: "Course",
    tabUrl: "/course",
  },
  {
    tabName: "Attempts",
    tabUrl: "/attempts",
  },
  {
    tabName: "Shop",
    tabUrl: "/shop",
  },
  {
    tabName: "Edit Bio",
    tabUrl: "/edit-bio",
  },
  {
    tabName: "Cart",
    tabUrl: "/shopping-cart",
  },
];

const Container = styled.div`
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const Nav = styled.div`
  width: 100%;
  min-height: 65px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  background-color: #fff;
  justify-content: space-between;
`;
const Logo = styled.div`
  width: 50px;
  height: 50px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const NavList = styled.ul`
  margin-left: 20px;
  display: flex;
  align-items: center;
  list-style: none;
  gap: 20px;
  margin-right: auto;
  li {
    margin: 0;
    padding: 13px 10px;
    /* border-bottom: 3px solid transparent; */
    transition: all 0.3s ease-in-out;
  }
  a {
    text-decoration: none;
    color: #f2b318;
    font-weight: 600;
    font-size: 15px;
    position: relative;
  }
`;

export default Navbar;
